import { NativeStackScreenProps } from "@react-navigation/native-stack";
import React, { useContext } from "react";
import { ScrollView, View } from "react-native";
import { Chip, Dialog, Divider, Portal } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import AddressItemMolecule from "../../components/molecules/address-item/address-item.component";
import ContentMolecule from "../../components/molecules/content/content.component";
import DescriptionMolecule from "../../components/molecules/description/description.component";
import PageTitleMolecule from "../../components/molecules/page-title/page-title.component";
import BadgeListOrganism from "../../components/organisms/badge-list/badge-list.component";
import OrderCostsDetailsOrganism from "../../components/organisms/order-costs-details/order-costs-details.component";
import { OrderCostsDetailsModel } from "../../components/organisms/order-costs-details/order-costs-details.model";
import PaymentInfoOrganism from "../../components/organisms/payment-info/payment-info.component";
import { StylesContext } from "../../context/styles/styles.context";
import { DeviceScreenContentComponent } from "../../dev-kit/components/device-screen-content/device-screen-content.component";
import { AppStoreActions } from "../../domain/app/app.store.actions";
import { OrderDomainModel } from "../../domain/order/order.domain.model";
import { OrderStoreActions } from "../../domain/order/store/order.store.actions";
import { OrderStoreSelectors } from "../../domain/order/store/order.store.selectors";
import { AppDispatch } from "../../domain/store";
import { RootStackParamList } from "../../navigation/navigation.model";
import { BadgeView } from "./badgeView/badge-view.component";
import styles from "./order-info.styles";

type Props = NativeStackScreenProps<RootStackParamList, "OrderInfo">;

export function OrderInfo({ navigation, route }: Props) {
  const contextStyles = useContext(StylesContext);

  const [visibleCardView, setVisibleCardView] = React.useState(false);
  const [badgeViewingIndex, setBadgeViewingIndex] = React.useState(0);
  const [parsedAdditionalProducts, setParsedAdditionalProducts] =
    React.useState<Array<OrderCostsDetailsModel.ProductDetails>>([]);

  const order = useSelector(OrderStoreSelectors.selectOrder);
  const dispatch = useDispatch<AppDispatch>();

  React.useEffect(() => {
    dispatch(AppStoreActions.setScreenBlockedForLoading(true));
    dispatch(AppStoreActions.setLoadingLabel("Carregando pedido..."));

    dispatch(OrderStoreActions.getOrder(route.params.orderId || "")).then(
      () => {
        dispatch(AppStoreActions.setScreenBlockedForLoading(false));
      }
    );
  }, []);

  React.useEffect(() => {
    if (!order) {
      return;
    }

    const additionalProducts = order.additionalProducts.map(
      (inOrderAdditionalProduct) => {
        let ammount = inOrderAdditionalProduct.quantity;

        if (inOrderAdditionalProduct.quantitySameAsBadgesLength) {
          ammount = order.product.badgeList.length;
        }

        const subTotalCost = ammount * inOrderAdditionalProduct.product.cost;

        return {
          unitCost: inOrderAdditionalProduct.product.cost,
          ammount,
          description: inOrderAdditionalProduct.product.name,
          subTotalCost,
        };
      }
    );

    setParsedAdditionalProducts(additionalProducts);
  }, [order]);

  const mapProductDetailsToOrganism = (
    productInfo: OrderDomainModel.Product
  ): OrderCostsDetailsModel.ProductDetails => {
    const unitCost =
      productInfo.cardType.cost +
      productInfo.frontTemplate.cost +
      (productInfo.backTemplate?.cost || 0);

    return {
      unitCost,
      subTotalCost: unitCost * productInfo.badgeList.length,
      description: `${productInfo.cardType.description}, Frente ${
        productInfo?.backTemplate ? "e verso" : "somente"
      }`,
      ammount: productInfo.badgeList.length,
    };
  };

  return (
    <DeviceScreenContentComponent>
      <ScrollView style={styles.container}>
        <ContentMolecule>
          <PageTitleMolecule># {order?.id}</PageTitleMolecule>

          {order?.date && (
            <DescriptionMolecule>
              Data pedido: {new Date(order.date).toLocaleString()}
            </DescriptionMolecule>
          )}

          <View style={styles.chipContainer}>
            <Chip mode="outlined">{order?.status}</Chip>
          </View>

          <OrderCostsDetailsOrganism
            destinationZipCode={order?.deliveryAddress?.zipCode || ""}
            totalCost={order?.totalCost || 0}
            productList={[
              ...(order?.product
                ? [mapProductDetailsToOrganism(order?.product)]
                : []),
              ...parsedAdditionalProducts,
            ]}
            freightCost={order?.freightCost || 0}
          />
        </ContentMolecule>

        <Divider />

        {order?.deliveryAddress && (
          <AddressItemMolecule
            address={order.deliveryAddress}
            selectedAddress={() => {}}
          >
            <DescriptionMolecule bold>Endereço de entrega:</DescriptionMolecule>
          </AddressItemMolecule>
        )}

        {!order?.payment?.paymentDateTime && order && (
          <>
            <Divider />

            <ContentMolecule>
              <DescriptionMolecule>Pagamento pendente</DescriptionMolecule>
              <PaymentInfoOrganism
                paymentMethod={order.paymentMethod}
                data={order.payment}
              ></PaymentInfoOrganism>
            </ContentMolecule>
          </>
        )}

        <Divider />

        {(() => {
          const badgeList = order?.product.badgeList.map((badge) => {
            return {
              name: badge.front.fields[0].value || "",
              imageUrl: badge.front.profileUri || "",
            };
          });
          return (
            <BadgeListOrganism
              dialogStyles={contextStyles.dialogStyle}
              badgeList={badgeList || []}
              selectedBadge={(badgeIndex) => {
                setBadgeViewingIndex(badgeIndex);
                setVisibleCardView(true);
              }}
              deleteBadge={() => {}}
            ></BadgeListOrganism>
          );
        })()}
      </ScrollView>

      <Portal>
        <Dialog
          style={contextStyles.dialogStyle}
          visible={visibleCardView}
          onDismiss={() => {
            setVisibleCardView(false);
          }}
        >
          <Dialog.Content>
            <ScrollView>
              <BadgeView
                logoUri={order?.product?.logoUri}
                backTemplate={order?.product?.backTemplate}
                frontTemplate={order?.product?.frontTemplate}
                badge={order?.product?.badgeList[badgeViewingIndex]}
              ></BadgeView>
            </ScrollView>
          </Dialog.Content>
        </Dialog>
      </Portal>
    </DeviceScreenContentComponent>
  );
}
