import { NativeStackScreenProps } from "@react-navigation/native-stack";
import React from "react";
import { FlatList, SafeAreaView } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import ContentMolecule from "../../../../components/molecules/content/content.component";
import { AppStoreActions } from "../../../../domain/app/app.store.actions";
import { NewOrderDomainModel } from "../../../../domain/new-order/new-order.domain.model";
import { NewOrderStoreActions } from "../../../../domain/new-order/store/new-order.store.actions";
import { NewOrderStoreSelectors } from "../../../../domain/new-order/store/new-order.store.selectors";
import { AppDispatch } from "../../../../domain/store";
import { Devices, useDevice } from "../../../../hooks/use-device";
import { NewOrderStackParamList } from "../../new-order.navigation.model";
import styles from "./additional-products.styles";
import AdditionalProductItem from "./components/additional-product-item/additional-product-item.component";

type Props = NativeStackScreenProps<
  NewOrderStackParamList,
  "AdditionalProducts"
>;

export function AdditionalProducts({ navigation, route }: Props) {
  const device = useDevice();

  const dispatch = useDispatch<AppDispatch>();

  React.useEffect(() => {
    dispatch(AppStoreActions.setScreenBlockedForLoading(true));
    dispatch(
      AppStoreActions.setLoadingLabel("Carregando produtos adicionais...")
    );
    dispatch(NewOrderStoreActions.getAdditionalProducts()).then(() => {
      dispatch(AppStoreActions.setScreenBlockedForLoading(false));
    });
  }, []);

  const additionalProducts = useSelector(
    NewOrderStoreSelectors.selectAdditionalProducts
  );

  const inOrderAdditionalProducts = useSelector(
    NewOrderStoreSelectors.selectInOrderAdditionalProducts
  );

  const renderItem = ({
    item,
  }: {
    item: NewOrderDomainModel.AdditionalProduct;
  }) => {
    return (
      <ContentMolecule>
        <AdditionalProductItem
          additionalProduct={item}
          inOrderAdditionalProduct={inOrderAdditionalProducts.find(
            (inOrderAdditionalProduct) =>
              inOrderAdditionalProduct.product.id === item.id
          )}
          onInOrderAdditionalProductChange={(newInOrderAdditionalProduct) => {
            dispatch(
              NewOrderStoreActions.upsertInOrderAdditionalProductList(
                newInOrderAdditionalProduct
              )
            );
          }}
        ></AdditionalProductItem>
      </ContentMolecule>
    );
  };

  return (
    <SafeAreaView style={styles.container}>
      <FlatList
        contentContainerStyle={
          device === Devices.DESKTOP
            ? { flexDirection: "row", flexWrap: "wrap" }
            : undefined
        }
        data={additionalProducts}
        renderItem={renderItem}
        keyExtractor={(item, index) => String(index)}
      />
    </SafeAreaView>
  );
}
