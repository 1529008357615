import axios, { AxiosResponse } from "axios";
import { from, map, Observable } from "rxjs";
import { HttpService } from "../../dev-kit/http.service";
import { UserDomainModel } from "../../domain/user/user.domain.model";
import { UserDomainMock } from "./user.domain.mock";

export class UserHttpService extends HttpService {
  static getUser(email: string) {
    const request = axios.get<UserDomainModel.User>(
      `${this.backendAddress}/users/self`
    );

    const observable: Observable<AxiosResponse<UserDomainModel.User>> =
      from(request);

    return observable.pipe(map((result) => result.data));
  }

  static createUser(user: UserDomainModel.BasicUser) {
    const request = axios.post<UserDomainModel.User>(
      `${this.backendAddress}/users/create`,
      user
    );

    const observable: Observable<AxiosResponse<UserDomainModel.User>> =
      from(request);

    return observable.pipe(map((result) => result.data));
  }

  static getUserAdresses(): Observable<Array<UserDomainModel.Address>> {
    const request = axios.get<Array<UserDomainModel.Address>>(
      `${this.backendAddress}/users/address-list`
    );

    const observable: Observable<
      AxiosResponse<Array<UserDomainModel.Address>>
    > = from(request);

    return observable.pipe(map((result) => result.data));
  }

  static createUserAdress(address: UserDomainModel.Address): Observable<void> {
    const request = axios.post<void>(
      `${this.backendAddress}/users/create-address`,
      address
    );

    const observable: Observable<AxiosResponse<void>> = from(request);

    return observable.pipe(map((result) => result.data));
  }
}
