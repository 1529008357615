import { StyleProp, ViewStyle } from "react-native/types";

const container: StyleProp<ViewStyle> = {
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export default { container };
