import { createSlice } from "@reduxjs/toolkit";
import { AppStoreModel } from "./app.store.model";
import { AppStoreActions } from "./app.store.actions";

export const storeKey = "app";
export const store = createSlice({
  name: storeKey,
  initialState: AppStoreModel.initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      AppStoreActions.setScreenBlockedForLoading.fulfilled,
      (state, action) => {
        state.isScreenBlockedForLoading = action.payload;
      }
    );

    builder.addCase(
      AppStoreActions.setLoadingLabel.fulfilled,
      (state, action) => {
        state.loadingLabel = action.payload;
      }
    );
  },
});

export default store.reducer;
