export namespace BadgeDomainModel {
  export interface CardType {
    id: string;
    title: string;
    subTitle: string;
    thumbUri: string;
    descriptionTitle: string;
    description: string;
    cost: number;
    primeinfoProductId: number;
    additionalInformation?: string;
  }

  export interface Template {
    id: string;
    type: "front" | "back";
    title: string;
    subTitle: string;
    descriptionTitle: string;
    description: string;
    cost: number;
    thumbUri: string;
    backgroundUri: string;
    fields: Array<TemplateField>;
  }

  export interface Badge {
    front: Front;
    back?: Back;
  }

  export interface Front {
    profileUri?: string;
    fields: Array<Field>;
  }

  export interface Back {
    fields: Array<Field>;
  }

  export interface Field {
    key: string;
    value?: string;
  }

  export interface TemplateField extends Field {
    fieldDescription: string;
    top: number;
    left: number;
    fontSize: number;
    fontColor: string;
  }

  export const getFields = (
    badge: BadgeDomainModel.Front | BadgeDomainModel.Back,
    template: BadgeDomainModel.Template | undefined
  ): Array<TemplateField> => {
    if (!template) {
      return [];
    }
    return template.fields.map((templateField) => {
      return {
        ...templateField,
        left: templateField.left || 0,
        value: badge.fields.find(
          (badgeField) => badgeField.key === templateField.key
        )?.value,
      };
    });
  };
}
