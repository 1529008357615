import { cloneDeep } from "lodash-es";
import * as React from "react";
import { Dimensions, Pressable, ScrollView, View } from "react-native";
import { TextInputMask, TextInputMaskProps } from "react-native-masked-text";
import {
  Button,
  Card,
  Dialog,
  IconButton,
  Portal,
  Switch,
  TextInput,
  Tooltip,
} from "react-native-paper";
import RenderHTML from "react-native-render-html";
import { useSelector } from "react-redux";
import CurrencyMolecule from "../../../../../../components/molecules/currency/currency.component";
import DescriptionMolecule from "../../../../../../components/molecules/description/description.component";
import { OptionsSelectorMoleculeComponent } from "../../../../../../components/molecules/options-selector/options-selector.component";
import { StylesContext } from "../../../../../../context/styles/styles.context";
import { NewOrderDomainModel } from "../../../../../../domain/new-order/new-order.domain.model";
import { NewOrderStoreSelectors } from "../../../../../../domain/new-order/store/new-order.store.selectors";
import { OrderDomainModel } from "../../../../../../domain/order/order.domain.model";
import { AddictionalProductItemModel } from "./additional-product-item.model";
import styles from "./additional-product-item.styles";

const createNewInOrderAdditionalProduct = (
  product: NewOrderDomainModel.AdditionalProduct
): OrderDomainModel.InOrderAdditionalProduct => {
  return {
    product,
    selectedOptions: [],
    quantity: 0,
    quantitySameAsBadgesLength: false,
  };
};

export default function AdditionalProductItem(
  props: AddictionalProductItemModel.Api
) {
  const contextStyles = React.useContext(StylesContext);

  const [visibleLongDescriptionDialog, setVisibleLongDescriptionDialog] =
    React.useState(false);

  const upsertProductAndEmit = (
    changer: (
      inOrderAdditionalProduct: OrderDomainModel.InOrderAdditionalProduct
    ) => void
  ) => {
    let inOrderAditionalProductToEmit: OrderDomainModel.InOrderAdditionalProduct;

    if (props.inOrderAdditionalProduct) {
      inOrderAditionalProductToEmit = cloneDeep(props.inOrderAdditionalProduct);
    } else {
      inOrderAditionalProductToEmit = createNewInOrderAdditionalProduct(
        props.additionalProduct
      );
    }

    changer(inOrderAditionalProductToEmit);
    props.onInOrderAdditionalProductChange(inOrderAditionalProductToEmit);
  };

  const optionGroups = (props: AddictionalProductItemModel.Api) => {
    return props.additionalProduct.optionGroups.map((optionGroup, index) => {
      const optionValue = props.inOrderAdditionalProduct?.selectedOptions?.find(
        (selectedOption) => selectedOption.key === optionGroup.key
      )?.value;
      return (
        <OptionsSelectorMoleculeComponent
          dialogStyles={contextStyles.dialogStyle}
          key={index}
          disabled={!props.inOrderAdditionalProduct?.quantity}
          options={optionGroup.values.map((option) => ({
            value: option,
            label: option,
          }))}
          label={optionGroup.key}
          chipMode
          chipIcon={
            !optionValue && props.inOrderAdditionalProduct?.quantity
              ? "alert-circle-outline"
              : undefined
          }
          value={optionValue}
          onOptionChange={(selectedOption) => {
            upsertProductAndEmit((inOrderAdditionalProduct) => {
              const optionAlreadySelected =
                inOrderAdditionalProduct.selectedOptions.find(
                  (selectedOption) => selectedOption.key === optionGroup.key
                );

              if (optionAlreadySelected) {
                optionAlreadySelected.value = selectedOption;
                return;
              }

              inOrderAdditionalProduct.selectedOptions.push({
                key: optionGroup.key,
                value: selectedOption,
              });
            });
          }}
        ></OptionsSelectorMoleculeComponent>
      );
    });
  };

  const badgeList = useSelector(NewOrderStoreSelectors.selectBadgeList);

  return (
    <>
      <Card style={{ minWidth: 300 }}>
        <Pressable
          onPress={() => {
            setVisibleLongDescriptionDialog(true);
          }}
        >
          <Card.Title
            title={props.additionalProduct.name}
            subtitle={props.additionalProduct.shortDescription}
            right={() => (
              <DescriptionMolecule fontSize={17}>
                <CurrencyMolecule>
                  {props.additionalProduct.cost}
                </CurrencyMolecule>{" "}
                un.
              </DescriptionMolecule>
            )}
            rightStyle={{ padding: 16 }}
          />

          <Card.Cover source={{ uri: props.additionalProduct.thumbnailUri }} />
        </Pressable>
        <View style={styles.actionsContainer}>
          <View style={styles.optionsContainer}>{optionGroups(props)}</View>

          <View style={{ flex: 3, ...styles.itemRightActions }}>
            {props.additionalProduct.oneByOrder && (
              <Switch
                value={!!props.inOrderAdditionalProduct?.quantity}
                onValueChange={(toggleState) => {
                  upsertProductAndEmit((inOrderAdditionalProduct) => {
                    inOrderAdditionalProduct.quantity = toggleState ? 1 : 0;
                  });
                }}
              />
            )}

            {!props.additionalProduct.oneByOrder && (
              <View style={styles.quantityInputContainer}>
                <Tooltip title="Usar quantidade de crachás no pedido">
                  <IconButton
                    selected={
                      props.inOrderAdditionalProduct?.quantitySameAsBadgesLength
                    }
                    icon="cards-outline"
                    mode="outlined"
                    size={20}
                    onPress={() => {
                      upsertProductAndEmit((inOrderAdditionalProduct) => {
                        inOrderAdditionalProduct.quantity = 1;
                        inOrderAdditionalProduct.quantitySameAsBadgesLength =
                          !props.inOrderAdditionalProduct
                            ?.quantitySameAsBadgesLength;
                      });
                    }}
                  />
                </Tooltip>
                <TextInput
                  right={<TextInput.Affix text="un." />}
                  disabled={
                    props.inOrderAdditionalProduct?.quantitySameAsBadgesLength
                  }
                  value={
                    !props.inOrderAdditionalProduct?.quantitySameAsBadgesLength
                      ? String(props.inOrderAdditionalProduct?.quantity)
                      : String(badgeList.length)
                  }
                  onChangeText={(value) => {
                    upsertProductAndEmit((inOrderAdditionalProduct) => {
                      inOrderAdditionalProduct.quantity = Number(value);
                    });
                  }}
                  maxLength={2}
                  render={(props) => {
                    const maskProps = {
                      ...props,
                      type: "only-numbers",
                    } as TextInputMaskProps;
                    return <TextInputMask {...maskProps} />;
                  }}
                  style={{ width: 80 }}
                />
              </View>
            )}
          </View>
        </View>
      </Card>

      <Portal>
        <Dialog
          style={contextStyles.dialogStyle}
          visible={visibleLongDescriptionDialog}
          onDismiss={() => {
            setVisibleLongDescriptionDialog(false);
          }}
        >
          <Dialog.Title>{props.additionalProduct.name}</Dialog.Title>

          <Dialog.Content
            style={{ maxHeight: Dimensions.get("window").height * 0.7 }}
          >
            <ScrollView>
              <RenderHTML
                contentWidth={Dimensions.get("window").width * 0.7}
                source={{
                  html: props.additionalProduct.longDescription,
                }}
              ></RenderHTML>
            </ScrollView>
          </Dialog.Content>

          <Dialog.Actions>
            <Button
              onPress={() => {
                setVisibleLongDescriptionDialog(false);
              }}
            >
              Ok
            </Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>
    </>
  );
}
