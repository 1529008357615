import { AuthDomainModel } from "../domain/auth/auth.domain.model";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { variables } from "../variables/";
import { Buffer } from "buffer";

export class TokenService {
  static async getTokens(): Promise<
    AuthDomainModel.CognitoTokenResponse | undefined
  > {
    const rawTokens = await AsyncStorage.getItem(
      variables.cognito.tokenLocalStorageKey
    );
    if (!rawTokens) {
      return undefined;
    }

    const tokens: AuthDomainModel.CognitoTokenResponse = JSON.parse(
      rawTokens || "{}"
    );
    return tokens;
  }

  static async getPayloadValue<T>(value: string): Promise<T | undefined> {
    const tokens = await TokenService.getTokens();
    if (!tokens) {
      return undefined;
    }

    const sections = tokens.id_token.split(".");

    const data = JSON.parse(
      Buffer.from(sections[1], "base64").toString("ascii")
    );
    return data?.[value] as T;
  }

  static async isTokenExpired(): Promise<boolean> {
    const exp = await TokenService.getPayloadValue<number>("exp");
    if (!exp) {
      return true;
    }

    if (exp < Date.now() / 1000) {
      return true;
    }

    return false;
  }

  static async removeAuthTokens(): Promise<void> {
    await AsyncStorage.removeItem(variables.cognito.tokenLocalStorageKey);
  }
}
