import { BadgeDomainModel } from "../../badge/badge.domain.model";
import { OrderDomainModel } from "../../order/order.domain.model";
import { UserDomainModel } from "../../user/user.domain.model";
import { NewOrderDomainModel } from "../new-order.domain.model";

export namespace NewOrderStoreModel {
  export interface StoreModel {
    cardTypeList: Array<BadgeDomainModel.CardType>;
    frontTemplateList: Array<BadgeDomainModel.Template>;
    backTemplateList: Array<BadgeDomainModel.Template>;
    additionalProductsList: Array<NewOrderDomainModel.AdditionalProduct>;
    selected?: Selected;
    inOrderAdditionalProductList: Array<OrderDomainModel.InOrderAdditionalProduct>;
    frontLogo?: string;
    badgeList: Array<BadgeDomainModel.Badge>;
    upsertingBadge?: BadgeDomainModel.Badge;
    deliveryAddress?: UserDomainModel.Address;
    freightCost: number;
    paymentMethod?: OrderDomainModel.PaymentMethod;
    paymentData?: OrderDomainModel.PaymentData;
    deliveryTime: number;
    reviewScreenshot: string;
  }

  export interface Selected {
    cardType?: BadgeDomainModel.CardType;
    frontTemplate?: BadgeDomainModel.Template;
    backTemplate?: BadgeDomainModel.Template;
  }

  export const initialState: StoreModel = {
    cardTypeList: [],
    frontTemplateList: [],
    backTemplateList: [],
    additionalProductsList: [],
    inOrderAdditionalProductList: [],
    badgeList: [],
    freightCost: 0,
    deliveryTime: 0,
    reviewScreenshot: "",
  };
}
