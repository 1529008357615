import { StyleProp, ViewStyle } from "react-native/types";

const container: StyleProp<ViewStyle> = {
  width: "100%",
  height: "100%",
  display: "flex",
  gap: 10,
};
const w100: StyleProp<ViewStyle> = { width: "100%" };

const styles = {
  container,
  w100,
};

export default styles;
