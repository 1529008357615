import React from "react";
import { Dimensions, ScrollView } from "react-native";
import { Button, Dialog, Portal, RadioButton } from "react-native-paper";
import { SelectorModalModel } from "./selector-modal.model";

export function SelectorModalComponent(props: SelectorModalModel) {
  const [selectedOption, setSelectedOption] = React.useState(props.input.value);

  return (
    <Portal>
      <Dialog
        style={props.input.dialogStyles}
        visible={props.input.visible}
        onDismiss={props.events.onDismiss}
      >
        <Dialog.Title>{props.input.label}</Dialog.Title>

        <Dialog.Content
          style={{ maxHeight: Dimensions.get("window").height * 0.5 }}
        >
          <ScrollView>
            <RadioButton.Group
              onValueChange={(value) => setSelectedOption(value)}
              value={selectedOption || ""}
            >
              {props.input.options.map((item, index) => {
                return (
                  <RadioButton.Item
                    key={index}
                    label={item.label}
                    value={item.value}
                  />
                );
              })}
            </RadioButton.Group>
          </ScrollView>
        </Dialog.Content>

        <Dialog.Actions>
          <Button
            onPress={() => {
              if (selectedOption) {
                props.events.onOptionSubmit(selectedOption);
              }
            }}
          >
            Pronto
          </Button>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  );
}
