import { createAsyncThunk } from '@reduxjs/toolkit';
import { firstValueFrom } from 'rxjs';
import { AuthHttpService } from './auth.http.service';

export namespace AuthStoreActions {
    export const requestToken = createAsyncThunk(
        `getToken`,
        async (authorizationCode: string) => {
            const tokenData = await firstValueFrom(AuthHttpService.requestToken(authorizationCode));
            return tokenData;
        }
    )

    export const clearToken = createAsyncThunk(
        `clearToken`,
        async () => { }
    )
}