import { View } from "react-native";
import { ProgressBar } from "react-native-paper";
import DescriptionMolecule from "../../../components/molecules/description/description.component";
import { useSelector } from "react-redux";
import { AppStoreSelectors } from "../../../domain/app/app.store.selectors";

export function LoadingBlockerComponent() {
  const isScreenBlockedForLoading = useSelector(
    AppStoreSelectors.selectIsScreenBlockedForLoading
  );
  const loadingLabel = useSelector(AppStoreSelectors.selectLoadingLabel);
  return (
    <>
      {isScreenBlockedForLoading && (
        <View
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            zIndex: 2,
          }}
        >
          <View
            style={{
              height: 100,
              width: "90%",
            }}
          >
            <ProgressBar indeterminate />

            <DescriptionMolecule bold color="white">
              {loadingLabel}
            </DescriptionMolecule>
          </View>
        </View>
      )}
    </>
  );
}
