import React from "react";
import { Devices } from "../../../../../../hooks/use-device";
import { ContentShellApiModel } from "./api.model";
import { ContentShellComponent as DesktopComponent } from "./desktop/component";
import { ContentShellComponent as TabletMobileComponent } from "./tablet-mobile/component";

export const ContentShellComponentsRegistries: Record<
  Devices,
  React.FC<ContentShellApiModel>
> = {
  DESKTOP: DesktopComponent,
  TABLET_MOBILE: TabletMobileComponent,
};
