import * as WebBrowser from "expo-web-browser";
import { ReactNode } from "react";
import { Platform, Pressable } from "react-native";
import { Button } from "react-native-paper";
import { variables } from "../../../variables/";

export function GoogleLoginComponent(props: {
  onPress?: () => void;
  buttonRenderer?: ReactNode;
}) {
  const handlePressButtonAsync = async () => {
    const url = `${
      variables.cognito.authAddress
    }/authorize?identity_provider=Google&redirect_uri=${
      variables.cognito.redirect_uri[Platform.OS]
    }&response_type=CODE&client_id=${
      variables.cognito.client_id
    }&scope=email%20openid%20phone`;
    if (Platform.OS === "web") {
      window.location.href = url;
    } else {
      await WebBrowser.openBrowserAsync(url);
    }
  };

  if (props.buttonRenderer) {
    return (
      <Pressable
        onPress={() => {
          handlePressButtonAsync();
          props?.onPress && props?.onPress();
        }}
      >
        {props.buttonRenderer}
      </Pressable>
    );
  }

  return (
    <Button
      onPress={() => {
        handlePressButtonAsync();
        props?.onPress && props?.onPress();
      }}
      icon={"account-check"}
      mode="outlined"
    >
      Login com Google
    </Button>
  );
}
