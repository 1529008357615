import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useContext } from "react";
import { Pressable, SafeAreaView, ScrollView, View } from "react-native";
import { FAB, IconButton } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import DescriptionMolecule from "../../../../components/molecules/description/description.component";
import BadgeListOrganism from "../../../../components/organisms/badge-list/badge-list.component";
import { StylesContext } from "../../../../context/styles/styles.context";
import { NewOrderStoreActions } from "../../../../domain/new-order/store/new-order.store.actions";
import { NewOrderStoreSelectors } from "../../../../domain/new-order/store/new-order.store.selectors";
import { AppDispatch } from "../../../../domain/store";
import { NewOrderStackParamList } from "../../new-order.navigation.model";
import styles from "./badge-list.styles";

type Props = NativeStackScreenProps<NewOrderStackParamList, "BadgeList">;

export function BadgeList({ navigation, route }: Props) {
  const contextStyles = useContext(StylesContext);

  const dispatch = useDispatch<AppDispatch>();
  const badges = useSelector(NewOrderStoreSelectors.selectBadgeList);

  const addBadge = () => {
    navigation.navigate("UpsertBadge", {});
  };

  return (
    <SafeAreaView style={styles.container}>
      {!badges.length && (
        <View style={styles.noBadgesHint}>
          <Pressable style={styles.addCardHint} onPress={addBadge}>
            <DescriptionMolecule textAlign="center" fontSize={20}>
              Você não tem nenhum crachá ainda.
            </DescriptionMolecule>

            <DescriptionMolecule textAlign="center" fontSize={20}>
              Comece adicionando crachás pressionando aqui!
            </DescriptionMolecule>

            <IconButton
              onPress={addBadge}
              style={{}}
              icon={"plus"}
            ></IconButton>
          </Pressable>
        </View>
      )}

      <ScrollView>
        <BadgeListOrganism
          dialogStyles={contextStyles.dialogStyle}
          allowDeletion
          badgeList={badges.map((badge) => ({
            name: badge.front.fields[0].value || "",
            imageUrl: badge.front.profileUri || "",
          }))}
          selectedBadge={(selectedIndex) => {
            navigation.navigate("UpsertBadge", {
              updateIndex: selectedIndex,
            });
          }}
          deleteBadge={(selectedIndex) => {
            dispatch(NewOrderStoreActions.deleteBadge(selectedIndex));
          }}
        ></BadgeListOrganism>
      </ScrollView>

      {badges.length ? (
        <FAB
          label={`Adicionar Crachá (${badges.length}/30)`}
          icon="plus"
          disabled={badges.length >= 30}
          style={styles.fab}
          onPress={addBadge}
        />
      ) : null}
    </SafeAreaView>
  );
}
