import { OrderDomainModel } from "../order.domain.model";

export namespace OrderStoreModel {
  export interface StoreModel {
    orders: Array<OrderDomainModel.Order>;
    order?: OrderDomainModel.Order;
    createdOrder?: OrderDomainModel.Order;
  }

  export const initialState: StoreModel = {
    orders: [],
  };
}
