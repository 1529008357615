import { of, pipe } from "rxjs";
import { catchError, delay, map, switchMap } from "rxjs/operators";
import { variables } from "../variables/";

export abstract class HttpService {
  static backendAddress = variables.beAddress;
  static authAddress = variables.cognito.authAddress;

  static applyMap<Raw, Target>(mapper: (raw: Raw) => Target) {
    const mapPipe = map(mapper);
    return mapPipe;
  }

  static applyMock<Raw>(mock: Raw, _delay?: number) {
    const mockPipe = pipe(
      delay(_delay || 5000),
      catchError(() => {
        return of(mock);
      }),
      switchMap(() => {
        console.warn(`[IMPORTANT] - Endpoint is using mocked data`, { mock });
        return of(mock);
      })
    );

    return mockPipe;
  }
}
