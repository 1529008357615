import "@expo/match-media";
import { useMediaQuery } from "react-responsive";

export const useDevice = (): Devices => {
  const isTabletOrMobile = useMediaQuery({
    maxDeviceWidth: 1023,
  });

  return isTabletOrMobile ? Devices.TABLET_MOBILE : Devices.DESKTOP;
};

export enum Devices {
  TABLET_MOBILE = "TABLET_MOBILE",
  DESKTOP = "DESKTOP",
}
