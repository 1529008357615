import { StyleProp, ViewStyle } from "react-native/types";

const itemRightActions: StyleProp<ViewStyle> = {
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "center",
  flexDirection: "column",
  gap: 5,
};

const actionsContainer: StyleProp<ViewStyle> = {
  display: "flex",
  flexDirection: "row",
  padding: 16,
  alignItems: "center",
};

const quantityInputContainer: StyleProp<ViewStyle> = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
};

const optionsContainer: StyleProp<ViewStyle> = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
};

const styles = {
  itemRightActions,
  quantityInputContainer,
  optionsContainer,
  actionsContainer,
};

export default styles;
