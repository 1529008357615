import * as ImagePicker from "expo-image-picker";
import { cloneDeep } from "lodash-es";
import React from "react";
import { Platform, TextInput as RNTextInput, View } from "react-native";
import { Button, TextInput } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import ContentMolecule from "../../../../../components/molecules/content/content.component";
import CardFrontOrganism from "../../../../../components/organisms/card-front/card-front.component";
import { BadgeDomainModel } from "../../../../../domain/badge/badge.domain.model";
import { NewOrderStoreActions } from "../../../../../domain/new-order/store/new-order.store.actions";
import { NewOrderStoreSelectors } from "../../../../../domain/new-order/store/new-order.store.selectors";
import { AppDispatch } from "../../../../../domain/store";
import { useDevice } from "../../../../../hooks/use-device";
import CameraShotComponent from "./components/camera-shot/camera-shot.component";
import { ContentShellComponentsRegistries } from "./device-components/content-shell/registries";
import styles from "./front.styles";

export function Front() {
  const device = useDevice();

  const nameInputRef = React.useRef<RNTextInput | null>(null);

  const dispatch = useDispatch<AppDispatch>();
  const [fields, setFields] = React.useState<
    Array<BadgeDomainModel.TemplateField>
  >([]);

  const selected = useSelector(NewOrderStoreSelectors.selectSelected);
  const frontLogo = useSelector(NewOrderStoreSelectors.selectFrontLogo);
  const upsertingBadge = useSelector(
    NewOrderStoreSelectors.selectUpsertingBadge
  );

  React.useEffect(() => {
    if (!upsertingBadge) {
      return;
    }

    const fields = BadgeDomainModel.getFields(
      upsertingBadge.front,
      selected?.frontTemplate
    );
    setFields(fields || []);
  }, [upsertingBadge, selected]);

  const setField = (newValue: string) => {
    if (!upsertingBadge) {
      return;
    }
    const updatedBadge = cloneDeep(upsertingBadge);
    updatedBadge.front.fields[0].value = newValue;
    dispatch(NewOrderStoreActions.setUpsertingBadge(updatedBadge));
  };

  const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      base64: true,
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [3, 4],
      quality: 0.6,
    });

    if (result.canceled) {
      return;
    }

    let profileImage = "";

    if (Platform.OS === "web") {
      profileImage = result.assets[0].uri;
    } else {
      const b64 = result.assets[0].base64;
      profileImage = b64 ? `data:image/jpeg;base64,${b64}` : "";
    }

    saveProfilePicture(profileImage);
  };

  const saveProfilePicture = (profileImage: string) => {
    if (!upsertingBadge) {
      return;
    }

    const updatedBadge = cloneDeep(upsertingBadge);
    updatedBadge.front.profileUri = profileImage;
    dispatch(NewOrderStoreActions.setUpsertingBadge(updatedBadge)).then(() => {
      nameInputRef.current?.focus();
    });
  };

  const ContentShellComponent = ContentShellComponentsRegistries[device];

  return (
    <ContentShellComponent
      badgePreview={
        <ContentMolecule>
          <View style={{ display: "flex", alignItems: "center" }}>
            <CardFrontOrganism
              backgroundUri={selected?.frontTemplate?.backgroundUri}
              logoUri={frontLogo}
              profileUri={upsertingBadge?.front.profileUri}
              fields={fields}
            ></CardFrontOrganism>
          </View>
        </ContentMolecule>
      }
      cameraActions={
        <ContentMolecule styles={{ paddingBottom: 0 }}>
          <View style={styles.pictureButtons}>
            <Button icon={"cellphone-link"} onPress={pickImage} mode="outlined">
              Selecionar foto
            </Button>

            <CameraShotComponent
              onSnap={(picture) => {
                if (Platform.OS === "web") {
                  saveProfilePicture(`${picture.uri}`);
                } else {
                  saveProfilePicture(
                    `data:image/jpeg;base64,${picture.base64}`
                  );
                }
              }}
            />
          </View>
        </ContentMolecule>
      }
      form={
        <ContentMolecule styles={{ paddingTop: 0 }}>
          <TextInput
            ref={nameInputRef}
            label={fields?.[0]?.fieldDescription}
            value={upsertingBadge?.front?.fields?.[0]?.value || ""}
            onChangeText={setField}
          />
        </ContentMolecule>
      }
    />
  );
}
