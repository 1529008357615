import { View } from "react-native";
import { FAB } from "react-native-paper";
import DescriptionMolecule from "../../../../../components/molecules/description/description.component";
import { GoogleLoginComponent } from "../../../../../dev-kit/components/google-login/google-login.component";
import { HomeActionsApiModel } from "../api.model";
import styles from "./styles";

export const HomeActionsComponent = (props: HomeActionsApiModel) => {
  return (
    <View style={styles.container}>
      <View style={styles.buttonContainer}>
        <View style={styles.button}>
          <FAB
            onPress={props.onNewOrderPress}
            icon={props.newOrderBadgesLenght ? "pencil" : "plus"}
            customSize={200}
            mode="elevated"
          />
        </View>

        <DescriptionMolecule textAlign="center">
          {props.newOrderBadgesLenght
            ? "Continue fazendo seu pedido"
            : "Começe criar crachás agora mesmo!"}
        </DescriptionMolecule>
      </View>

      {props.isLoggedIn && (
        <View style={styles.buttonContainer}>
          <View style={styles.button}>
            <FAB
              onPress={props.onSelfOrdersListPress}
              icon={"eye"}
              customSize={200}
              mode="elevated"
            />
          </View>

          <DescriptionMolecule textAlign="center">
            Acompanhar seus pedidos
          </DescriptionMolecule>
        </View>
      )}

      {!props.isLoggedIn && (
        <View style={styles.buttonContainer}>
          <View style={styles.button}>
            <GoogleLoginComponent
              onPress={props.onLoginPress}
              buttonRenderer={
                <FAB icon={"login"} customSize={200} mode="elevated" />
              }
            />
          </View>

          <DescriptionMolecule textAlign="center">
            Fazer login para acompanhar seus pedidos
          </DescriptionMolecule>
        </View>
      )}
    </View>
  );
};
