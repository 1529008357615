import { StyleProp, ViewStyle } from "react-native/types";
import { PropsWithChildren } from "react";
import { View } from "react-native";

export default function ContentMolecule(
  props: PropsWithChildren<{ styles?: StyleProp<ViewStyle> }>
) {
  return (
    <View
      style={{
        padding: 15,
        display: "flex",
        gap: 10,
        ...(props?.styles as object),
      }}
    >
      {props.children}
    </View>
  );
}
