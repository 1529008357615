import { StyleProp, ViewStyle } from "react-native/types";

const container: StyleProp<ViewStyle> = { height: '100%' }

const fab: StyleProp<ViewStyle> = {
    position: 'absolute',
    margin: 16,
    right: 0,
    bottom: 0,
}

export default { container, fab };