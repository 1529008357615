import { Camera, CameraCapturedPicture, CameraType } from "expo-camera";
import { useContext, useRef, useState } from "react";
import { Platform, View } from "react-native";
import { Button, Dialog, IconButton, Portal } from "react-native-paper";
import { StylesContext } from "../../../../../../../context/styles/styles.context";
import styles from "./camera-shot.styles";

export default function CameraShotComponent(props: {
  onSnap: (picture: CameraCapturedPicture) => void;
}) {
  const contextStyles = useContext(StylesContext);

  const CameraShot = () => {
    const [shooting, setShooting] = useState(false);
    const [type, setType] = useState(CameraType.back);
    const [permission, requestPermission] = Camera.useCameraPermissions();
    const ref = useRef<Camera>(null);

    function toggleCameraType() {
      setType((current) =>
        current === CameraType.back ? CameraType.front : CameraType.back
      );
    }

    const takePhoto = async () => {
      setShooting(true);
      const picture = await ref?.current?.takePictureAsync({
        base64: true,
        quality: 0.6,
      });
      setShooting(false);
      if (picture) {
        props.onSnap(picture);
        setVisibleModal(false);
      }
    };

    if (!permission) {
      return <View />;
    }

    if (!permission.granted) {
      return (
        <View>
          <Button mode={"contained"} onPress={requestPermission}>
            Solicitar permissão para usar a câmera
          </Button>
        </View>
      );
    }

    return (
      <View style={styles.content}>
        <View style={styles.ratio34}>
          <Camera style={styles.ratio34} type={type} ref={ref}></Camera>
        </View>

        <View style={styles.cameraActions}>
          {Platform.OS !== "web" && (
            <IconButton
              icon="camera-flip-outline"
              size={20}
              onPress={toggleCameraType}
            />
          )}

          <Button
            disabled={shooting}
            icon={"camera"}
            mode="outlined"
            onPress={takePhoto}
          >
            Tirar foto
          </Button>
        </View>
      </View>
    );
  };

  const [visibleModal, setVisibleModal] = useState(false);

  const hideModal = () => {
    setVisibleModal(false);
  };

  return (
    <>
      <Button
        icon={"camera"}
        mode="outlined"
        onPress={() => {
          setVisibleModal(true);
        }}
      >
        Tirar foto
      </Button>

      <Portal>
        <Dialog
          style={contextStyles.dialogStyle}
          visible={visibleModal}
          onDismiss={hideModal}
        >
          <Dialog.Content>{visibleModal && <CameraShot />}</Dialog.Content>

          <Dialog.Actions>
            <Button
              onPress={() => {
                hideModal();
              }}
            >
              Fechar
            </Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>
    </>
  );
}
