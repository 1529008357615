import { configureStore } from "@reduxjs/toolkit";
import AppStoreReducer, * as AppStore from "./app/app.store";
import AuthStoreReducer, * as AuthStore from "./auth/auth.store";
import OrderStoreReducer, * as OrderStore from "./order/store/order.store";
import UserStoreReducer, * as UserStore from "./user/store/user.store";
import NewOrderStoreReducer, * as NewOrderStore from "./new-order/store/new-order.store";
import { AppStoreModel } from "./app/app.store.model";
import { AuthStoreModel } from "./auth/auth.store.model";
import { OrderStoreModel } from "./order/store/order.store.model";
import { UserStoreModel } from "./user/store/user.store.model";
import { NewOrderStoreModel } from "./new-order/store/new-order.store.model";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Subject, debounceTime, skip } from "rxjs";

export const globalStore = configureStore<GlobalStoreModel>({
  reducer: {
    [AppStore.storeKey]: AppStoreReducer,
    [AuthStore.storeKey]: AuthStoreReducer,
    [OrderStore.storeKey]: OrderStoreReducer,
    [UserStore.storeKey]: UserStoreReducer,
    [NewOrderStore.storeKey]: NewOrderStoreReducer,
  },
});

export interface GlobalStoreModel {
  app: AppStoreModel.StoreModel;
  auth: AuthStoreModel.StoreModel;
  order: OrderStoreModel.StoreModel;
  user: UserStoreModel.StoreModel;
  "new-order": NewOrderStoreModel.StoreModel;
}

const storeChange$ = new Subject<void>();

storeChange$.pipe(skip(1), debounceTime(500)).subscribe(() => {
  persistState(globalStore.getState());
});

globalStore.subscribe(() => {
  storeChange$.next();
});

export const persistState = async (state: GlobalStoreModel) => {
  const persistNewOrder: Partial<NewOrderStoreModel.StoreModel> = {
    selected: state["new-order"].selected,
    frontLogo: state["new-order"].frontLogo,
    badgeList: state["new-order"].badgeList,
    deliveryAddress: state["new-order"].deliveryAddress,
    inOrderAdditionalProductList:
      state["new-order"].inOrderAdditionalProductList,
  };
  const serializedNewOrderState = JSON.stringify(persistNewOrder);
  AsyncStorage.setItem(NewOrderStore.storeKey, serializedNewOrderState);
};

export type AppDispatch = typeof globalStore.dispatch;
