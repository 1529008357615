import { Image, View, Text } from "react-native";
import { CardFrontModel } from "./card-front.model";
import styles, { cardDimensions } from "./card-front.styles";

export default function CardFrontOrganism(props: CardFrontModel.Api) {
  const fields = (fields: Array<CardFrontModel.Field>) => {
    return fields.map((field, index) => {
      return (
        <View
          key={index}
          style={{
            width: cardDimensions.width,
            position: "absolute",
            top: cardDimensions.height * field.top,
          }}
        >
          <Text
            style={{
              color: field.fontColor,
              textAlign: "center",
              fontFamily: "IRoboto",
              fontSize: cardDimensions.height * field.fontSize,
            }}
          >
            {field.value}
          </Text>
        </View>
      );
    });
  };

  return (
    <View style={styles.borderBox}>
      <View style={styles.card}>
        <Image
          style={styles.backGround}
          source={{ uri: props.backgroundUri }}
        />

        <Image
          style={styles.profilePicture}
          source={
            props.profileUri
              ? { uri: props.profileUri }
              : require("./images/profile.jpg")
          }
        />

        <Image
          style={styles.logoPicture}
          source={{ uri: props.logoUri }}
          resizeMode="contain"
        />

        {fields(props.fields)}
      </View>
    </View>
  );
}
