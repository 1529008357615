import * as Clipboard from "expo-clipboard";
import { Image, View } from "react-native";
import { Button } from "react-native-paper";
import { snackBarSubject } from "../../../dev-kit/snackbar.service";
import DescriptionMolecule from "../../molecules/description/description.component";
import { PaymentInfoModel } from "./payment-info.model";

export default function PaymentInfoOrganism(props: PaymentInfoModel.Api) {
  if (props.paymentMethod === PaymentInfoModel.PaymentMethod.CREDIT_CARD) {
    return (
      <View style={{ display: "flex", alignItems: "center" }}>
        <DescriptionMolecule>
          Pagamento por cartão de crédito
        </DescriptionMolecule>
      </View>
    );
  }

  const pixData = props.data as PaymentInfoModel.PixPayment;

  return (
    <>
      <View style={{ display: "flex", alignItems: "center" }}>
        <Image
          style={{ width: 150, height: 150 }}
          source={{ uri: pixData.qrcode }}
        ></Image>
      </View>

      <Button
        icon={"content-copy"}
        mode="outlined"
        onPress={async () => {
          await Clipboard.setStringAsync(pixData.textCode);
          snackBarSubject.next("Copiado!");
        }}
      >
        Copiar codigo PIX
      </Button>
    </>
  );
}
