import { StyleProp, ViewStyle } from "react-native/types";
import { cardDimensions } from "../../../components/organisms/card-front/card-front.styles";

const badgeContainer: StyleProp<ViewStyle> = {
  alignSelf: "center",
  width: (cardDimensions.width * 2 + 20) / 2,
  height: cardDimensions.height / 2 + 20,
};

const badge: StyleProp<ViewStyle> = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  gap: 20,
  marginTop: "-35%",
  marginLeft: "-50%",
  marginRight: "-50%",
  transform: [
    {
      scale: 0.5,
    },
  ],
};

export default { badgeContainer, badge };
