import { StyleProp, ViewStyle } from "react-native/types";

const container: StyleProp<ViewStyle> = { height: "100%", padding: 20 };

const actions: StyleProp<ViewStyle> = {
  display: "flex",
  flexDirection: "column",
  gap: 15,
};

export default { container, actions };
