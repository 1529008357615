import { NativeStackScreenProps } from "@react-navigation/native-stack";
import axios from "axios";
import { toPng } from "html-to-image";
import React, { useContext } from "react";
import { Dimensions, Platform, ScrollView, View } from "react-native";
import { Button, Checkbox, Dialog, Portal } from "react-native-paper";
import RenderHTML from "react-native-render-html";
import { captureRef } from "react-native-view-shot";
import { useDispatch, useSelector } from "react-redux";
import ContentMolecule from "../../../components/molecules/content/content.component";
import CardBackOrganism from "../../../components/organisms/card-back/card-back.component";
import CardFrontOrganism from "../../../components/organisms/card-front/card-front.component";
import { StylesContext } from "../../../context/styles/styles.context";
import { BadgeDomainModel } from "../../../domain/badge/badge.domain.model";
import { NewOrderStoreActions } from "../../../domain/new-order/store/new-order.store.actions";
import { NewOrderStoreSelectors } from "../../../domain/new-order/store/new-order.store.selectors";
import { AppDispatch } from "../../../domain/store";
import { variables } from "../../../variables";
import { CheckoutStackParamList } from "../checkout.navigation.model";
import styles from "./review-badges.styles";

type Props = NativeStackScreenProps<CheckoutStackParamList, "ReviewBadges">;

export function ReviewBadges({ navigation, route }: Props) {
  const contextStyles = useContext(StylesContext);

  const dispatch = useDispatch<AppDispatch>();

  const contentRef = React.useRef<View>(null);

  const badges = useSelector(NewOrderStoreSelectors.selectBadgeList);
  const selected = useSelector(NewOrderStoreSelectors.selectSelected);
  const frontLogo = useSelector(NewOrderStoreSelectors.selectFrontLogo);

  const [termsAndConditions, setTermsAndConditions] = React.useState("");

  const [read, setRead] = React.useState(false);
  const [agreed, setAgreed] = React.useState(false);

  const [visibleTermsDialog, setVisibleTermsDialog] = React.useState(false);

  const BadgeView = (badges: Array<BadgeDomainModel.Badge>) => {
    return badges.map((badge, index) => {
      return (
        <View key={index} style={styles.badgeContainer}>
          <View style={styles.badge}>
            <CardFrontOrganism
              backgroundUri={selected?.frontTemplate?.backgroundUri}
              logoUri={frontLogo}
              profileUri={badge?.front.profileUri}
              fields={BadgeDomainModel.getFields(
                badge.front,
                selected?.frontTemplate
              )}
            ></CardFrontOrganism>

            {selected?.backTemplate && badge.back && (
              <CardBackOrganism
                backgroundUri={selected?.backTemplate?.backgroundUri}
                fields={BadgeDomainModel.getFields(
                  badge.back,
                  selected?.backTemplate
                )}
              ></CardBackOrganism>
            )}
          </View>
        </View>
      );
    });
  };

  return (
    <>
      <ScrollView>
        <View ref={contentRef} collapsable={false}>
          {BadgeView(badges)}

          <ContentMolecule>
            <Button
              onPress={() => {
                axios
                  .get(
                    `${
                      variables.termsAndConditionsUrl
                    }?timestamp=${new Date().getTime()}`
                  )
                  .then((response) => {
                    setTermsAndConditions(
                      response.data["terms-and-conditions"]
                    );
                    setVisibleTermsDialog(true);
                  });
              }}
            >
              Leia os termos e condições
            </Button>

            <Checkbox.Item
              disabled={!read}
              label="Eu concordo com os termos e condições"
              status={agreed ? "checked" : "unchecked"}
              onPress={() => {
                setAgreed(!agreed);
              }}
            />

            <Button
              mode="outlined"
              disabled={!agreed}
              onPress={async () => {
                const b64result = await screenCaptors[Platform.OS](contentRef);

                dispatch(
                  NewOrderStoreActions.setReviewScreenshot(b64result)
                ).then(() => {
                  navigation.navigate("Submit");
                });
              }}
            >
              Ir para pagamento
            </Button>
          </ContentMolecule>
        </View>
      </ScrollView>

      <Portal>
        <Dialog
          style={contextStyles.dialogStyle}
          visible={visibleTermsDialog}
          onDismiss={() => {
            setVisibleTermsDialog(false);
          }}
        >
          <Dialog.Title>Termos e Condições</Dialog.Title>

          <Dialog.Content
            style={{ maxHeight: Dimensions.get("window").height * 0.7 }}
          >
            <ScrollView>
              <RenderHTML
                contentWidth={Dimensions.get("window").width * 0.7}
                source={{
                  html: termsAndConditions,
                }}
              ></RenderHTML>
            </ScrollView>
          </Dialog.Content>

          <Dialog.Actions>
            <Button
              onPress={() => {
                setVisibleTermsDialog(false);
                setRead(true);
              }}
            >
              Ok
            </Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>
    </>
  );
}

const expoCaptor = (ref: React.RefObject<View>) => {
  return captureRef(ref, {
    result: "base64",
    quality: 0.1,
    format: "png",
  });
};

const screenCaptors: Record<
  typeof Platform.OS,
  (ref: React.RefObject<View>) => Promise<string>
> = {
  ios: expoCaptor,
  android: expoCaptor,
  windows: expoCaptor,
  macos: expoCaptor,
  web: (ref) => {
    return toPng(ref.current as unknown as HTMLElement, {
      cacheBust: false,
    }).then((dataUrl) => {
      return dataUrl.replace("data:image/png;base64,", "");
    });
  },
};
