import { cloneDeep } from "lodash-es";
import React from "react";
import { View } from "react-native";
import { TextInput } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import ContentMolecule from "../../../../../components/molecules/content/content.component";
import CardBackOrganism from "../../../../../components/organisms/card-back/card-back.component";
import { BadgeDomainModel } from "../../../../../domain/badge/badge.domain.model";
import { NewOrderStoreActions } from "../../../../../domain/new-order/store/new-order.store.actions";
import { NewOrderStoreSelectors } from "../../../../../domain/new-order/store/new-order.store.selectors";
import { AppDispatch } from "../../../../../domain/store";
import { useDevice } from "../../../../../hooks/use-device";
import { ContentShellComponentsRegistries } from "./device-components/content-shell/registries";

export function Back() {
  const device = useDevice();

  const dispatch = useDispatch<AppDispatch>();
  const [fields, setFields] = React.useState<
    Array<BadgeDomainModel.TemplateField>
  >([]);

  const selected = useSelector(NewOrderStoreSelectors.selectSelected);
  const upsertingBadge = useSelector(
    NewOrderStoreSelectors.selectUpsertingBadge
  );

  React.useEffect(() => {
    if (!upsertingBadge || !upsertingBadge.back) {
      return;
    }

    const fields = BadgeDomainModel.getFields(
      upsertingBadge.back,
      selected?.backTemplate
    );
    setFields(fields || []);
  }, [upsertingBadge, selected]);

  const setField = (newValue: string, key: string) => {
    if (!upsertingBadge || !upsertingBadge?.back) {
      return;
    }
    const updatedBadge = cloneDeep(upsertingBadge);

    if (!updatedBadge || !updatedBadge?.back) {
      return;
    }

    const fieldToUpdate = updatedBadge.back.fields.find(
      (field) => field.key === key
    );
    if (!fieldToUpdate) {
      updatedBadge.back.fields.push({ value: newValue, key });
    } else {
      fieldToUpdate.value = newValue;
    }
    dispatch(NewOrderStoreActions.setUpsertingBadge(updatedBadge));
  };

  const ContentShellComponent = ContentShellComponentsRegistries[device];

  return (
    <ContentShellComponent
      badgePreview={
        <ContentMolecule>
          <View style={{ display: "flex", alignItems: "center" }}>
            <CardBackOrganism
              backgroundUri={selected?.backTemplate?.backgroundUri}
              fields={fields}
            ></CardBackOrganism>
          </View>
        </ContentMolecule>
      }
      form={
        <ContentMolecule>
          {(() => {
            return fields.map((field, index) => (
              <TextInput
                key={index}
                label={field.fieldDescription}
                value={
                  upsertingBadge?.back?.fields?.find(
                    (upsertingField) => upsertingField.key === field.key
                  )?.value || ""
                }
                onChangeText={(newValue) => {
                  setField(newValue, field.key);
                }}
              />
            ));
          })()}
        </ContentMolecule>
      }
    />
  );
}
