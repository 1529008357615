import { StyleProp, ViewStyle } from "react-native/types";

const container: StyleProp<ViewStyle> = { width: '100%', display: 'flex', gap: 10 };
const row: StyleProp<ViewStyle> = { flexDirection: 'row' };
const colProduct: StyleProp<ViewStyle> = { flex: 3 };
const colCost: StyleProp<ViewStyle> = { flex: 1, alignItems: 'flex-end' };

const styles = {
    container,
    row,
    colProduct,
    colCost
}

export default styles;