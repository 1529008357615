import { NativeStackScreenProps } from "@react-navigation/native-stack";
import * as ImagePicker from "expo-image-picker";
import React from "react";
import { Platform, View } from "react-native";
import { Button } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import ContentMolecule from "../../../../components/molecules/content/content.component";
import CardFrontOrganism from "../../../../components/organisms/card-front/card-front.component";
import { NewOrderStoreActions } from "../../../../domain/new-order/store/new-order.store.actions";
import { NewOrderStoreSelectors } from "../../../../domain/new-order/store/new-order.store.selectors";
import { AppDispatch } from "../../../../domain/store";
import { useDevice } from "../../../../hooks/use-device";
import { NewOrderStackParamList } from "../../new-order.navigation.model";
import { ContentShellComponentsRegistries } from "./device-components/content-shell/registries";

type Props = NativeStackScreenProps<NewOrderStackParamList, "SetFrontLogo">;

export function SetFrontLogo({ navigation, route }: Props) {
  const device = useDevice();

  const selected = useSelector(NewOrderStoreSelectors.selectSelected);
  const dispatch = useDispatch<AppDispatch>();

  const frontLogo = useSelector(NewOrderStoreSelectors.selectFrontLogo);
  const [logoImage, setLogoImage] = React.useState<string | undefined>(
    frontLogo
  );

  React.useEffect(() => {
    setLogoImage(frontLogo);
  }, [frontLogo]);

  const selectedLogo = () => {
    dispatch(NewOrderStoreActions.setFrontLogo(logoImage));
    navigation.navigate("BadgeList");
  };

  const pickImage = async () => {
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      base64: true,
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      quality: 0.6,
    });

    if (result.canceled) {
      return;
    }

    if (Platform.OS === "web") {
      setLogoImage(result.assets[0].uri);
      return;
    }

    const b64 = result.assets[0].base64;
    const dataUrl = b64 ? `data:image/jpeg;base64,${b64}` : "";
    setLogoImage(dataUrl);
  };

  const ContentShellComponent = ContentShellComponentsRegistries[device];

  return (
    <ContentShellComponent
      selectionButton={
        <Button icon={"camera"} onPress={pickImage} mode="outlined">
          Selecionar do Dispositivo
        </Button>
      }
      badgePreview={
        <ContentMolecule>
          <View style={{ display: "flex", alignItems: "center" }}>
            <CardFrontOrganism
              backgroundUri={selected?.frontTemplate?.backgroundUri}
              logoUri={logoImage}
              fields={[]}
            ></CardFrontOrganism>
          </View>
        </ContentMolecule>
      }
      confirmButton={
        <Button icon={"check"} onPress={selectedLogo} mode="contained">
          Confirmar {!logoImage ? "(Sem logo)" : ""}
        </Button>
      }
      removeLogoButton={
        logoImage && (
          <Button
            icon={"cancel"}
            onPress={() => {
              setLogoImage(undefined);
            }}
            mode="outlined"
          >
            Remover Logo
          </Button>
        )
      }
    />
  );
}
