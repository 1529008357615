import { StyleProp, ViewStyle } from "react-native/types";

const container: StyleProp<ViewStyle> = {
  display: "flex",
  flexDirection: "row",
  padding: 40,
  gap: 40,
};

const buttonContainer: StyleProp<ViewStyle> = {
  display: "flex",
  width: 200,
  gap: 20,
};

const button: StyleProp<ViewStyle> = {
  width: 200,
  height: 200,
};

export default { container, button, buttonContainer };
