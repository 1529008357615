import axios, { AxiosResponse } from "axios";
import { from, map, Observable } from "rxjs";
import { HttpService } from "../../dev-kit/http.service";
import { BadgeDomainModel } from "../badge/badge.domain.model";
import { NewOrderDomainModel } from "./new-order.domain.model";
import { NewOrderDomainMock } from "./new-order.domain.mock";

export class NewOrderHttpService extends HttpService {
  static getCardTypes(): Observable<Array<BadgeDomainModel.CardType>> {
    const request = axios.get<Array<BadgeDomainModel.CardType>>(
      `${this.backendAddress}/new-order/card-types`
    );

    const observable: Observable<
      AxiosResponse<Array<BadgeDomainModel.CardType>>
    > = from(request);

    return observable.pipe(map((result) => result.data));
  }

  static getFrontTemplateList(): Observable<Array<BadgeDomainModel.Template>> {
    const request = axios.get<Array<BadgeDomainModel.Template>>(
      `${this.backendAddress}/new-order/template-list/?type=front`
    );

    const observable: Observable<
      AxiosResponse<Array<BadgeDomainModel.Template>>
    > = from(request);

    return observable.pipe(map((result) => result.data));
  }

  static getBackTemplateList(): Observable<Array<BadgeDomainModel.Template>> {
    const request = axios.get<Array<BadgeDomainModel.Template>>(
      `${this.backendAddress}/new-order/template-list/?type=back`
    );

    const observable: Observable<
      AxiosResponse<Array<BadgeDomainModel.Template>>
    > = from(request);

    return observable.pipe(map((result) => result.data));
  }

  static getAdditionalProducts(): Observable<
    Array<NewOrderDomainModel.AdditionalProduct>
  > {
    const request = axios.get<Array<NewOrderDomainModel.AdditionalProduct>>(
      `${this.backendAddress}/new-order/additional-products`
    );

    const observable: Observable<
      AxiosResponse<Array<NewOrderDomainModel.AdditionalProduct>>
    > = from(request);

    return observable.pipe(map((result) => result.data));
  }

  static getFreightCost(
    zipCode: string
  ): Observable<NewOrderDomainModel.FreightInfo> {
    const request = axios.get<NewOrderDomainModel.FreightInfo>(
      `${this.backendAddress}/new-order/freight-cost/?toZipCode=${zipCode}`
    );

    const observable: Observable<
      AxiosResponse<NewOrderDomainModel.FreightInfo>
    > = from(request);

    return observable.pipe(map((result) => result.data));
  }
}
