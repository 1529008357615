import * as React from "react";
import { Image, SafeAreaView, View } from "react-native";
import {
  Button,
  Dialog,
  Divider,
  IconButton,
  List,
  Portal,
  Text,
} from "react-native-paper";
import { BadgeListModel } from "./badge-list.model";
import styles from "./badge-list.styles";

export default function BadgeListOrganism(props: BadgeListModel.Api) {
  const [selectedIndex, setSelectedIndex] = React.useState<
    number | undefined
  >();

  const [
    visibleBadgeDeleteConfirmationDialog,
    setVisibleBadgeDeleteConfirmationDialog,
  ] = React.useState(false);

  const hideBadgeDeleteConfirmationDialog = () => {
    setVisibleBadgeDeleteConfirmationDialog(false);
  };

  const renderItems = (list: Array<BadgeListModel.Badge>) => {
    return list.map((item, index) => (
      <View key={index}>
        <View style={styles.item}>
          <List.Item
            style={{ flex: 8 }}
            title={item.name}
            description={``}
            left={(props) => (
              <View>
                <Image source={{ width: 50, height: 50, uri: item.imageUrl }} />
              </View>
            )}
          />

          <View style={{ flex: 4, ...styles.itemActions }}>
            <IconButton
              onPress={() => {
                props.selectedBadge(index);
              }}
              icon={"eye"}
            ></IconButton>

            {props?.allowDeletion && (
              <IconButton
                onPress={() => {
                  setSelectedIndex(index);
                  setVisibleBadgeDeleteConfirmationDialog(true);
                }}
                icon={"delete"}
              ></IconButton>
            )}
          </View>
        </View>
        <Divider />
      </View>
    ));
  };

  return (
    <>
      <SafeAreaView style={styles.container}>
        {renderItems(props.badgeList)}
      </SafeAreaView>

      <Portal>
        <Dialog
          style={props.dialogStyles}
          visible={visibleBadgeDeleteConfirmationDialog}
          onDismiss={hideBadgeDeleteConfirmationDialog}
        >
          <Dialog.Title>Atenção</Dialog.Title>
          <Dialog.Content>
            <Text variant="bodyMedium">
              Tem certeza que deseja excluir esse crachá?
            </Text>
          </Dialog.Content>
          <Dialog.Actions>
            <Button onPress={hideBadgeDeleteConfirmationDialog}>Não</Button>
            <Button
              mode="contained"
              onPress={() => {
                if (selectedIndex == null) {
                  return;
                }
                props.deleteBadge(selectedIndex);
                hideBadgeDeleteConfirmationDialog();
              }}
            >
              Sim
            </Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>
    </>
  );
}
