import { createSlice } from "@reduxjs/toolkit";
import { UserStoreModel } from "./user.store.model";
import { UserStoreActions } from "./user.store.actions";

export const storeKey = "user";
export const store = createSlice({
  name: storeKey,
  initialState: UserStoreModel.initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(UserStoreActions.getUser.fulfilled, (state, action) => {
      state.user = action.payload;
    });

    builder.addCase(
      UserStoreActions.getUserAdresses.fulfilled,
      (state, action) => {
        state.userAddressList = action.payload;
      }
    );

    builder.addCase(
      UserStoreActions.createUser.fulfilled,
      (state, action) => {}
    );

    builder.addCase(
      UserStoreActions.createUserAdress.fulfilled,
      (state, action) => {}
    );
  },
});

export default store.reducer;
