import { useFonts } from "expo-font";
import {
  MD3LightTheme as DefaultTheme,
  Provider as ThemeProvider,
  configureFonts,
} from "react-native-paper";
import { ThemeProp } from "react-native-paper/lib/typescript/src/types";
import { Provider } from "react-redux";
import { Subject } from "rxjs";
import AsyncStorageReduxLoader from "./AsyncStorageReduxLoader";
import { StylesContextProvider } from "./context/styles/styles.context";
import { LoadingBlockerComponent } from "./dev-kit/components/loading-blocker/loading-blocker.component";
import "./dev-kit/http.interceptor";
import { SnackbarService } from "./dev-kit/snackbar.service";
import { globalStore } from "./domain/store";
import { Navigation } from "./navigation/navigation";

export const snackSubject = new Subject<string>();

export default function App() {
  const [fontsLoaded, fontError] = useFonts({
    IRoboto: require("./assets/fonts/Roboto-Regular.ttf"),
  });

  if (!fontsLoaded && !fontError) {
    return null;
  }

  const fontConfig = {
    fontFamily: "IRoboto",
  };

  const theme: ThemeProp = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
    },
    fonts: configureFonts({ config: fontConfig }),
  };

  return (
    <StylesContextProvider>
      <ThemeProvider theme={theme}>
        <Provider store={globalStore}>
          <AsyncStorageReduxLoader></AsyncStorageReduxLoader>
          <Navigation />
          <LoadingBlockerComponent></LoadingBlockerComponent>
        </Provider>

        <SnackbarService />
      </ThemeProvider>
    </StylesContextProvider>
  );
}
