import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { Divider, IconButton, Menu } from "react-native-paper";
import { RootStackParamList } from "../../../../navigation/navigation.model";
import React from "react";

export function NavigationMenuComponent(props: {
  navigation: NativeStackNavigationProp<RootStackParamList>;
}) {
  const [visible, setVisible] = React.useState(false);

  const openMenu = () => setVisible(true);

  const closeMenu = () => setVisible(false);

  return (
    <Menu
      visible={visible}
      onDismiss={closeMenu}
      anchor={<IconButton icon={"menu"} onPress={openMenu}></IconButton>}
    >
      <Menu.Item
        onPress={() => {
          props.navigation.navigate("NewOrder", { screen: "CardType" });
        }}
        title="Tipo de cartão"
      />
      <Menu.Item
        onPress={() => {
          props.navigation.navigate("NewOrder", {
            screen: "FrontTemplate",
          });
        }}
        title="Template da frente"
      />
      <Menu.Item
        onPress={() => {
          props.navigation.navigate("NewOrder", { screen: "BackTemplate" });
        }}
        title="Template do verso"
      />
      <Menu.Item
        onPress={() => {
          props.navigation.navigate("NewOrder", { screen: "SetFrontLogo" });
        }}
        title="Logotipo da frente"
      />
      <Divider />
      <Menu.Item
        onPress={() => {
          props.navigation.navigate("NewOrder", { screen: "BadgeList" });
        }}
        title="Crachás"
      />
    </Menu>
  );
}
