import { OrderDomainModel } from "./order.domain.model";

export namespace OrderDomainServicerderDomainService {
  export const calculateAdditionalProductsTotalCost = (
    inOrderAdditionalProducts: Array<OrderDomainModel.InOrderAdditionalProduct>,
    badgeListLength: number
  ): number => {
    let additionalProductsTotalCost = 0;

    inOrderAdditionalProducts.forEach((inOrderAdditionalProduct) => {
      let ammount = inOrderAdditionalProduct.quantity;

      if (inOrderAdditionalProduct.quantitySameAsBadgesLength) {
        ammount = badgeListLength;
      }

      const subTotalCost = ammount * inOrderAdditionalProduct.product.cost;
      additionalProductsTotalCost += subTotalCost;
    });

    return additionalProductsTotalCost;
  };
}
