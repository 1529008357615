import { StyleProp, ViewStyle } from "react-native/types";

const container: StyleProp<ViewStyle> = {};

const fabProceed: StyleProp<ViewStyle> = {
  position: "absolute",
  margin: 16,
  right: 0,
  bottom: 0,
};

const fabReview: StyleProp<ViewStyle> = {
  position: "absolute",
  margin: 16,
  left: 0,
  bottom: 0,
};

export default { container, fabProceed, fabReview };
