import {
  NativeStackScreenProps,
  createNativeStackNavigator,
} from "@react-navigation/native-stack";
import React, { useContext } from "react";
import { View } from "react-native";
import {
  Button,
  Dialog,
  Divider,
  IconButton,
  Portal,
  Text,
} from "react-native-paper";
import { useDispatch } from "react-redux";
import { StylesContext } from "../../context/styles/styles.context";
import { NewOrderStoreActions } from "../../domain/new-order/store/new-order.store.actions";
import { AppDispatch } from "../../domain/store";
import { RootStackParamList } from "../../navigation/navigation.model";
import { CostSummaryComponent } from "./components/cost-summary/cost-summary.component";
import { NavigationMenuComponent } from "./components/navigation-menu/navigation-menu.component";
import { NewOrderStackParamList } from "./new-order.navigation.model";
import styles from "./new-order.styles";
import { CardType } from "./steps/1-card-type/cart-type.page";
import { FrontTemplate } from "./steps/2-front-template/front-template.page";
import { BackTemplate } from "./steps/3-back-template/back-template.page";
import { SetFrontLogo } from "./steps/4-set-front-logo/set-front-logo.page";
import { AdditionalProducts } from "./steps/additional-products/additional-products.page";
import { BadgeList } from "./steps/badge-list/badge-list.page";
import { UpsertBadge } from "./steps/upsert-badge/upsert-badge.page";

type Props = NativeStackScreenProps<RootStackParamList, "NewOrder">;

const NewOrderStack = createNativeStackNavigator<NewOrderStackParamList>();

export function NewOrder({ navigation, route }: Props) {
  const contextStyles = useContext(StylesContext);

  const dispatch = useDispatch<AppDispatch>();
  const [isUpserting, setIsUpserting] = React.useState(false);

  const resetNewOrder = () => {
    dispatch(NewOrderStoreActions.resetNewOrder());
    navigation.reset({
      index: 0,
      routes: [{ name: "Home" }, { name: "NewOrder" }],
    });
  };

  const [
    visibleResetOrderConfirmationDialog,
    setVisibleResetOrderConfirmationDialog,
  ] = React.useState(false);

  const hideResetOrderConfirmationDialog = () => {
    setVisibleResetOrderConfirmationDialog(false);
  };

  return (
    <>
      <NewOrderStack.Navigator
        screenOptions={{
          headerLeft: () => {
            return (
              <NavigationMenuComponent
                navigation={navigation}
              ></NavigationMenuComponent>
            );
          },
          headerRight: () => (
            <>
              <IconButton
                onPress={() => {
                  setVisibleResetOrderConfirmationDialog(true);
                }}
                icon={"restart"}
              ></IconButton>
            </>
          ),
        }}
      >
        <NewOrderStack.Screen
          name="CardType"
          component={CardType}
          options={{ title: "Selecione o tipo do cartão" }}
        />

        <NewOrderStack.Screen
          name="FrontTemplate"
          component={FrontTemplate}
          options={{ title: "Selecione o template da frente" }}
        />

        <NewOrderStack.Screen
          name="BackTemplate"
          component={BackTemplate}
          options={{ title: "Selecione o template do verso" }}
        />

        <NewOrderStack.Screen
          name="SetFrontLogo"
          component={SetFrontLogo}
          options={{ title: "Selecione o logotipo da frente" }}
        />

        <NewOrderStack.Screen
          name="BadgeList"
          component={BadgeList}
          listeners={{
            focus: () => {
              setIsUpserting(false);
            },
          }}
          options={{ title: "Crachás no meu pedido" }}
        />

        <NewOrderStack.Screen
          name="UpsertBadge"
          component={UpsertBadge}
          listeners={{
            focus: () => {
              setIsUpserting(true);
            },
            beforeRemove: () => {
              setIsUpserting(false);
            },
          }}
          options={{ title: "Definir Crachá" }}
        />

        <NewOrderStack.Screen
          name="AdditionalProducts"
          component={AdditionalProducts}
          options={{ title: "Produtos adicionais" }}
        />
      </NewOrderStack.Navigator>

      {!isUpserting && (
        <>
          <Divider />

          <View style={styles.costInfo}>
            <CostSummaryComponent
              navigation={navigation}
              route={route}
            ></CostSummaryComponent>
          </View>
        </>
      )}
      <Portal>
        <Dialog
          style={contextStyles.dialogStyle}
          visible={visibleResetOrderConfirmationDialog}
          onDismiss={hideResetOrderConfirmationDialog}
        >
          <Dialog.Title>Atenção</Dialog.Title>
          <Dialog.Content>
            <Text variant="bodyMedium">
              Tem certeza que deseja recomeçar o pedido do zero?
            </Text>
          </Dialog.Content>
          <Dialog.Actions>
            <Button onPress={hideResetOrderConfirmationDialog}>Não</Button>
            <Button
              mode="contained"
              onPress={() => {
                resetNewOrder();
                hideResetOrderConfirmationDialog();
              }}
            >
              Sim
            </Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>
    </>
  );
}
