import { EnvironmentVars } from "./model";

const generalRedirectUri =
  "https://express.primeinfo-id.com.br/primeexpress/auth-redirect.html";

export const variables: EnvironmentVars = {
  beAddress: "https://5kbn0cc7v1.execute-api.us-east-1.amazonaws.com/prod",
  pagSeguroPublicKey:
    "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA0tPon/GtXF+ppLLIZgZAKZGzwr9Q7Kydb6vsxCImu/DzoBlhnZ/+ZvMAbaLSPVeZG1I8qChBvjGt4pMa/yKQ6QuOlTSpt+nPt3ZhGWRkebTKy/7yFCQSrLrEtF7ww8oNUU05two8U3ljUe8pps0Cj3PwCy7MuOZOhd24iI0MA5NaSzEEfUx1MhHHMc1lHr39tSN6qR93xxQXAryFWLzsmLxgk5vkpnT6ibRzmeTaDLuboV7HfiV3kJNwsqBArKNqruilokKc8t2J1FmajSRCrormJrFOJ6dQ9+OGn0Ezku1H8ei6LRpcSRAzYj2W+CGH5fZiByRsfVCHsJDkqQCskwIDAQAB",
  termsAndConditionsUrl:
    "https://express.primeinfo-id.com.br/terms-and-conditions.json",
  cognito: {
    tokenLocalStorageKey: "tokens",
    authAddress: "https://primeexpress.auth.us-east-1.amazoncognito.com/oauth2",
    client_id: "15kqp6q34q0d3u5pjh2evnt6h9",
    redirect_uri: {
      web: "https://express.primeinfo-id.com.br/AuthorizationCodeReceiver",
      android: generalRedirectUri,
      ios: generalRedirectUri,
      windows: generalRedirectUri,
      macos: generalRedirectUri,
    },
  },
};
