import { useIsFocused } from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import axios from "axios";
import { throttle } from "lodash-es";
import React, { useContext } from "react";
import { ScrollView } from "react-native";
import { Divider } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import AddressItemMolecule from "../../../components/molecules/address-item/address-item.component";
import ContentMolecule from "../../../components/molecules/content/content.component";
import DescriptionMolecule from "../../../components/molecules/description/description.component";
import AddressFormOrganism from "../../../components/organisms/address-form/address-form.component";
import { AddressFormModel } from "../../../components/organisms/address-form/address-form.model";
import { StylesContext } from "../../../context/styles/styles.context";
import { AppStoreActions } from "../../../domain/app/app.store.actions";
import { NewOrderStoreActions } from "../../../domain/new-order/store/new-order.store.actions";
import { AppDispatch } from "../../../domain/store";
import { UserStoreActions } from "../../../domain/user/store/user.store.actions";
import { UserStoreSelectors } from "../../../domain/user/store/user.store.selectors";
import { UserDomainModel } from "../../../domain/user/user.domain.model";
import { CheckoutStackParamList } from "../checkout.navigation.model";

type Props = NativeStackScreenProps<CheckoutStackParamList, "AddressSelection">;

export function AddressSelection({ navigation, route }: Props) {
  const contextStyles = useContext(StylesContext);

  const isFocused = useIsFocused();
  const dispatch = useDispatch<AppDispatch>();
  const addressList = useSelector(UserStoreSelectors.selectUserAddressList);
  const [addressInForm, setAddressInForm] =
    React.useState<AddressFormModel.Address>();

  React.useEffect(() => {
    if (!isFocused) {
      return;
    }
    dispatch(AppStoreActions.setScreenBlockedForLoading(true));
    dispatch(AppStoreActions.setLoadingLabel("Carregando endereços..."));

    dispatch(UserStoreActions.getUserAdresses()).then(() => {
      dispatch(AppStoreActions.setScreenBlockedForLoading(false));
    });
  }, [isFocused]);

  const AddressList = (list: Array<UserDomainModel.Address>) => {
    return list.map((address, index) => (
      <AddressItemMolecule
        key={index}
        address={address}
        selectedAddress={setAddressAndMoveOn}
      />
    ));
  };

  const createAddress = (address: UserDomainModel.Address) => {
    const createAddress = dispatch(
      UserStoreActions.createUserAdress(address)
    ).unwrap();

    createAddress.then(() => {
      setAddressAndMoveOn(address);
    });
  };

  const setAddressAndMoveOn = (address: UserDomainModel.Address) => {
    dispatch(NewOrderStoreActions.setDeliveryAddress(address)).then(() => {
      navigation.navigate("Summary");
    });
  };

  const queryAndSetAddress = (zipcode: string) => {
    const _axios = axios.create();
    _axios
      .get<ViaCepResponse>(`https://viacep.com.br/ws/${zipcode}/json/`)
      .then((response) => {
        if (!response?.data) {
          return;
        }

        const address = response.data;
        setAddressInForm({
          zipCode: zipcode,
          street: address.logradouro,
          state: address.uf,
          reference: "",
          houseNumber: "",
          district: address.bairro,
          city: address.localidade,
          additionalInfo: "",
        });
      })
      .catch(() => {});
  };

  return (
    <ScrollView>
      <ContentMolecule>
        <DescriptionMolecule>Últimos endereços de entrega:</DescriptionMolecule>
      </ContentMolecule>

      {AddressList(addressList)}

      <Divider></Divider>

      <ContentMolecule>
        <DescriptionMolecule bold>
          Novo endereço de entrega:
        </DescriptionMolecule>

        <AddressFormOrganism
          dialogStyles={contextStyles.dialogStyle}
          address={addressInForm}
          zipCodeChanged={throttle(queryAndSetAddress, 1000)}
          formSubmit={createAddress}
        ></AddressFormOrganism>
      </ContentMolecule>
    </ScrollView>
  );
}

interface ViaCepResponse {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge: string;
  gia: string;
  ddd: string;
  siafi: string;
}
