import React from "react";
import { Devices } from "../../../../hooks/use-device";
import { HomeActionsApiModel } from "./api.model";
import { HomeActionsComponent as DesktopComponent } from "./desktop/component";
import { HomeActionsComponent as TabletMobileComponent } from "./tablet-mobile/component";

export const HomeActionsComponentsRegistries: Record<
  Devices,
  React.FC<HomeActionsApiModel>
> = {
  DESKTOP: DesktopComponent,
  TABLET_MOBILE: TabletMobileComponent,
};
