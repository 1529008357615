import * as React from "react";
import { View } from "react-native";
import { TextInputMask, TextInputMaskProps } from "react-native-masked-text";
import { Button, TextInput } from "react-native-paper";
import { SignupInfoFormModel } from "./signup-info-form.model";
import styles from "./signup-info-form.styles";
import { _isValidCpf } from "./signup-info-form.validators";

export default function SignupInfoFormOrganism(props: SignupInfoFormModel.Api) {
  const [isValidForm, setIsValidForm] = React.useState(false);

  const [isValidDocument, setIsValidDocument] = React.useState(true);
  const [name, setName] = React.useState("");
  const [document, setDocument] = React.useState("");
  const [phone, setPhone] = React.useState("");

  const validateAndSetDocument = (document: string) => {
    setIsValidDocument(_isValidCpf(document));
    setDocument(document);
  };

  const submitForm = () => {
    props.formSubmit({
      name,
      document,
      phone,
    });
  };

  React.useEffect(() => {
    setIsValidForm(!!(document && name && phone && isValidDocument));
  }, [isValidDocument, name, document, phone]);

  return (
    <View style={styles.container}>
      <TextInput
        style={styles.w100}
        value={name}
        onChangeText={setName}
        mode="flat"
        label="Nome completo"
        placeholder="Nome completo"
      />

      <TextInput
        style={styles.w100}
        value={document}
        onChangeText={validateAndSetDocument}
        mode="flat"
        label="CPF"
        render={(props) => {
          const maskProps = { ...props, type: "cpf" } as TextInputMaskProps;
          return <TextInputMask {...maskProps} />;
        }}
      />

      <TextInput
        style={styles.w100}
        value={phone}
        onChangeText={setPhone}
        mode="flat"
        label="Telefone/Celular"
        render={(props) => {
          const maskProps = {
            ...props,
            type: "cel-phone",
          } as TextInputMaskProps;
          return <TextInputMask {...maskProps} />;
        }}
      />

      <Button
        disabled={!isValidForm}
        icon="check"
        mode="contained"
        onPress={submitForm}
      >
        Salvar
      </Button>
    </View>
  );
}
