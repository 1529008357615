import axios, { AxiosResponse } from "axios";
import { from, map, Observable } from "rxjs";
import { HttpService } from "../../dev-kit/http.service";
import { AuthDomainModel } from "./auth.domain.model";
import { variables } from "../../variables/";
import { Platform } from "react-native";

export class AuthHttpService extends HttpService {
  static requestToken(
    authorizationCode: string
  ): Observable<AuthDomainModel.CognitoTokenResponse> {
    const data = {
      grant_type: "authorization_code",
      client_id: variables.cognito.client_id,
      redirect_uri: variables.cognito.redirect_uri[Platform.OS],
      code: authorizationCode,
    };

    const request = axios.post<AuthDomainModel.CognitoTokenResponse>(
      `${this.authAddress}/token`,
      data,
      { headers: { "content-type": "application/x-www-form-urlencoded" } }
    );

    const observable: Observable<
      AxiosResponse<AuthDomainModel.CognitoTokenResponse>
    > = from(request);

    return observable.pipe(map((result) => result.data));
  }
}
