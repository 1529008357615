import { View } from "react-native";
import { Button } from "react-native-paper";
import ContentMolecule from "../../../../../components/molecules/content/content.component";
import DescriptionMolecule from "../../../../../components/molecules/description/description.component";
import { GoogleLoginComponent } from "../../../../../dev-kit/components/google-login/google-login.component";
import { HomeActionsApiModel } from "../api.model";
import styles from "./styles";

export const HomeActionsComponent = (props: HomeActionsApiModel) => {
  return (
    <View style={styles.container}>
      <ContentMolecule>
        <DescriptionMolecule>
          {props.newOrderBadgesLenght
            ? "Continue fazendo seu pedido."
            : "Começe criar crachás agora mesmo!"}
        </DescriptionMolecule>

        <Button
          onPress={props.onNewOrderPress}
          icon={props.newOrderBadgesLenght ? "pencil" : "plus"}
          mode="contained"
        >
          {props.newOrderBadgesLenght ? "Continuar..." : "Criar crachás!"}
        </Button>
      </ContentMolecule>

      <ContentMolecule>
        <DescriptionMolecule>
          {!props.isLoggedIn && "Ou faça login para acompanhar seus pedidos!"}
        </DescriptionMolecule>

        {props.isLoggedIn ? (
          <Button mode="outlined" onPress={props.onSelfOrdersListPress}>
            Pedidos
          </Button>
        ) : (
          <GoogleLoginComponent onPress={props.onLoginPress} />
        )}
      </ContentMolecule>
    </View>
  );
};
