import { NavigationProp } from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import React from "react";
import { View } from "react-native";
import { ActivityIndicator, Button, ProgressBar } from "react-native-paper";
import { useDispatch } from "react-redux";
import { Subscription, interval } from "rxjs";
import { AsyncStorageReduxLoaded } from "../../../AsyncStorageReduxLoader";
import ContentMolecule from "../../../components/molecules/content/content.component";
import DescriptionMolecule from "../../../components/molecules/description/description.component";
import PaymentInfoOrganism from "../../../components/organisms/payment-info/payment-info.component";
import { snackBarSubject } from "../../../dev-kit/snackbar.service";
import { TokenService } from "../../../dev-kit/token.service";
import { BadgeDomainModel } from "../../../domain/badge/badge.domain.model";
import { NewOrderStoreActions } from "../../../domain/new-order/store/new-order.store.actions";
import { NewOrderStoreModel } from "../../../domain/new-order/store/new-order.store.model";
import { OrderDomainModel } from "../../../domain/order/order.domain.model";
import { OrderDomainServicerderDomainService } from "../../../domain/order/order.domain.service";
import { OrderStoreActions } from "../../../domain/order/store/order.store.actions";
import { AppDispatch, globalStore } from "../../../domain/store";
import { UserDomainModel } from "../../../domain/user/user.domain.model";
import { RootStackParamList } from "../../../navigation/navigation.model";
import { CheckoutStackParamList } from "../checkout.navigation.model";
import styles from "./submit.styles";

type Props = NativeStackScreenProps<CheckoutStackParamList, "Submit">;

let AsyncStorageReduxLoadedSubscription: Subscription;
let CheckPaymentIntervalSubscription: Subscription;

export function Submit({ navigation, route }: Props) {
  const [confirmedOrder, setConfirmedOrder] = React.useState<
    undefined | OrderDomainModel.Order
  >();

  const [paymentConfirmed, setPaymentConfirmed] = React.useState(false);

  const [performing, setPerforming] = React.useState("");
  const dispatch = useDispatch<AppDispatch>();

  React.useEffect(() => {
    AsyncStorageReduxLoadedSubscription = AsyncStorageReduxLoaded.subscribe(
      async (loaded) => {
        if (!loaded) {
          return;
        }

        createOrder();
      }
    );

    return () => {
      CheckPaymentIntervalSubscription &&
        CheckPaymentIntervalSubscription.unsubscribe();
      AsyncStorageReduxLoadedSubscription.unsubscribe();
    };
  }, []);

  const createOrder = async () => {
    const selected = globalStore.getState()["new-order"].selected;
    const badgeList = globalStore.getState()["new-order"].badgeList;
    const inOrderAdditionalProducts =
      globalStore.getState()["new-order"].inOrderAdditionalProductList;
    const frontLogo = globalStore.getState()["new-order"].frontLogo;
    const freightCost = globalStore.getState()["new-order"].freightCost;
    const deliveryAddress = globalStore.getState()["new-order"].deliveryAddress;
    const reviewScreenshot =
      globalStore.getState()["new-order"].reviewScreenshot;
    const paymentMethod = globalStore.getState()["new-order"].paymentMethod;
    const paymentData = globalStore.getState()["new-order"].paymentData;

    try {
      await validateOrder({
        selected,
        badgeList,
        deliveryAddress,
        freightCost,
        paymentMethod,
        paymentData,
      });
    } catch {
      error("Pedido inválido");
      return;
    }

    let createdOrder: OrderDomainModel.Order;
    try {
      createdOrder = await requestOrderCreation({
        selected: selected!,
        badgeList,
        deliveryAddress: deliveryAddress!,
        freightCost,
        frontLogo,
        inOrderAdditionalProducts,
        paymentMethod: paymentMethod!,
        paymentData: paymentData!,
      });
    } catch (err) {
      error("Não foi possível registrar o novo pedido");
      return;
    }

    try {
      await uploadReviewScreenshot(createdOrder, reviewScreenshot);
    } catch (err) {
      error("Não foi possível fazer o upload do screenshot de revisão");
      return;
    }

    try {
      await uploadFrontLogo(createdOrder, frontLogo);
    } catch (err) {
      error("Não foi possível fazer o upload do logotipo");
      return;
    }

    try {
      await uploadBadgesImages(badgeList, createdOrder);
    } catch {
      error("Não foi possível fazer o upload da imagem dos crachás");
      return;
    }

    let confirmedOrder: OrderDomainModel.Order;
    try {
      confirmedOrder = await confirmOrderValidation(createdOrder);
    } catch {
      error("Não foi possível confirmar o pedido");
      return;
    }

    setConfirmedOrder(confirmedOrder);
    dispatch(NewOrderStoreActions.resetNewOrder());
    checkPaymentInInterval(confirmedOrder);
  };

  const validateOrder = (requirements: {
    selected?: NewOrderStoreModel.Selected;
    badgeList: Array<BadgeDomainModel.Badge>;
    deliveryAddress?: UserDomainModel.Address;
    freightCost: number;
    paymentMethod?: OrderDomainModel.PaymentMethod;
    paymentData?: OrderDomainModel.PaymentData;
  }) => {
    setPerforming("Validando pedido");
    if (
      !requirements.selected ||
      !requirements.selected.frontTemplate ||
      !requirements.selected.cardType ||
      !requirements.badgeList?.length ||
      !requirements.deliveryAddress ||
      !requirements.freightCost ||
      !requirements.paymentMethod ||
      !requirements.paymentData
    ) {
      throw new Error();
    }
  };

  const requestOrderCreation = async (requirements: {
    selected: NewOrderStoreModel.Selected;
    badgeList: Array<BadgeDomainModel.Badge>;
    deliveryAddress: UserDomainModel.Address;
    freightCost: number;
    frontLogo?: string;
    inOrderAdditionalProducts: Array<OrderDomainModel.InOrderAdditionalProduct>;
    paymentMethod: OrderDomainModel.PaymentMethod;
    paymentData: OrderDomainModel.PaymentData;
  }) => {
    const userEmail = await TokenService.getPayloadValue<string>("email");

    setPerforming("Registrando novo pedido");
    const cardTypeCost = requirements.selected?.cardType?.cost || 0;
    const frontTemplateCost = requirements.selected?.frontTemplate?.cost || 0;
    const backTemplateCost = requirements.selected?.backTemplate?.cost || 0;
    const unitCost = cardTypeCost + frontTemplateCost + backTemplateCost;
    const badgeCosts = unitCost * requirements.badgeList.length;
    const additionalProductsTotalCost =
      OrderDomainServicerderDomainService.calculateAdditionalProductsTotalCost(
        requirements.inOrderAdditionalProducts,
        requirements.badgeList.length
      );

    const newOrder: OrderDomainModel.Order = {
      email: userEmail || "",
      valid: false,
      totalCost: Number(
        (
          badgeCosts +
          requirements.freightCost +
          additionalProductsTotalCost
        ).toFixed(2)
      ),
      status: "Aguardando pagamento",
      thumb: "",
      product: {
        logoUri: await createSimpleFileName("logo", requirements.frontLogo),
        backTemplate: requirements.selected.backTemplate,
        frontTemplate: requirements.selected.frontTemplate!,
        cardType: requirements.selected.cardType!,
        badgeList: await simplifyBadgeListImages(requirements.badgeList),
      },
      paymentMethod: requirements.paymentMethod,
      payment: requirements.paymentData,
      id: "",
      freightCost: requirements.freightCost,
      deliveryAddress: requirements.deliveryAddress,
      date: "",
      additionalProducts: requirements.inOrderAdditionalProducts,
    };

    return await dispatch(OrderStoreActions.createOrder(newOrder)).unwrap();
  };

  const uploadReviewScreenshot = async (
    order: OrderDomainModel.Order,
    screenshot?: string
  ) => {
    if (!screenshot) {
      return;
    }
    setPerforming("Realizando upload do screenshot de revisão");

    await dispatch(
      OrderStoreActions.uploadOrderImage({
        orderId: order.id,
        fileName: "screenshotReview.png",
        base64image: screenshot,
      })
    ).unwrap();
  };

  const uploadFrontLogo = async (
    order: OrderDomainModel.Order,
    frontLogo?: string
  ) => {
    if (!frontLogo) {
      return;
    }
    setPerforming("Realizando upload do logotipo da frente");

    const image = await imageDataB64(frontLogo);
    await dispatch(
      OrderStoreActions.uploadOrderImage({
        orderId: order.id,
        fileName: "logo." + image.extension,
        base64image: image.base64,
      })
    ).unwrap();
  };

  const uploadBadgesImages = async (
    badgeList: Array<BadgeDomainModel.Badge>,
    order: OrderDomainModel.Order
  ) => {
    for (let index = 0; index < badgeList.length; index++) {
      const badge = badgeList[index];
      if (!badge.front.profileUri) {
        return;
      }
      setPerforming(
        "Realizando upload da imagem do crachá número " + (index + 1)
      );

      const image = await imageDataB64(badge.front.profileUri);

      await dispatch(
        OrderStoreActions.uploadOrderImage({
          orderId: order.id,
          fileName: index + 1 + "." + image.extension,
          base64image: image.base64,
        })
      ).unwrap();
    }
  };

  const confirmOrderValidation = async (order: OrderDomainModel.Order) => {
    setPerforming("Confirmando validade do pedido");

    return await dispatch(
      OrderStoreActions.confirmOrderValidation(order.id)
    ).unwrap();
  };

  const checkPaymentInInterval = (order: OrderDomainModel.Order) => {
    CheckPaymentIntervalSubscription = interval(10000).subscribe(() => {
      dispatch(OrderStoreActions.getOrder(order.id))
        .unwrap()
        .then((order) => {
          if (order.payment.paymentDateTime) {
            setPaymentConfirmed(true);
            CheckPaymentIntervalSubscription.unsubscribe();
          }
        });
    });
    navigation.addListener("blur", () => {
      CheckPaymentIntervalSubscription &&
        CheckPaymentIntervalSubscription.unsubscribe();
    });
  };

  const error = (message: string) => {
    snackBarSubject.next(message);
    navigation
      .getParent<NavigationProp<RootStackParamList>>()
      .navigate("NewOrder", {});
  };

  const simplifyBadgeListImages = async (
    badgeList: Array<BadgeDomainModel.Badge>
  ): Promise<Array<BadgeDomainModel.Badge>> => {
    const newBadgeList: Array<BadgeDomainModel.Badge> = [];

    for (let index = 0; index < badgeList.length; index++) {
      const element = badgeList[index];
      if (!element.front.profileUri) {
        newBadgeList.push(element);
        continue;
      }
      const filename = await createSimpleFileName(
        String(index + 1),
        element.front.profileUri
      );

      newBadgeList.push({
        ...element,
        front: {
          ...element.front,
          profileUri: filename,
        },
      });
    }
    return newBadgeList;
  };

  const createSimpleFileName = async (
    name: string,
    imageDataUrl?: string
  ): Promise<string | undefined> => {
    if (!imageDataUrl) {
      return;
    }
    const mimeType = imageDataUrl.substring(
      imageDataUrl.indexOf(":") + 1,
      imageDataUrl.indexOf(";")
    );
    return `${name}.${mimeType.split("/")[1]}`;
  };

  const imageDataB64 = async (
    dataUrl: string
  ): Promise<{ extension: string; base64: string }> => {
    const regex = /^data:.+\/(.+);base64,(.*)$/;

    const matches = (dataUrl as string).match(regex);
    const extension = matches?.[1];
    const b64 = matches?.[2];
    return {
      extension: extension || "",
      base64: b64 || "",
    };
  };

  return (
    <>
      <View style={styles.container}>
        {!confirmedOrder && (
          <View>
            <ActivityIndicator size={"large"} animating={true} />

            <ContentMolecule>
              <DescriptionMolecule>{performing}</DescriptionMolecule>
            </ContentMolecule>
          </View>
        )}

        {confirmedOrder && (
          <View>
            <ContentMolecule>
              <DescriptionMolecule bold>
                Pedido # {confirmedOrder?.id} realizado com sucesso!
              </DescriptionMolecule>
            </ContentMolecule>

            {!paymentConfirmed && (
              <View>
                <ContentMolecule>
                  <PaymentInfoOrganism
                    paymentMethod={confirmedOrder.paymentMethod}
                    data={confirmedOrder.payment}
                  ></PaymentInfoOrganism>
                </ContentMolecule>

                <ContentMolecule>
                  {confirmedOrder.paymentMethod ===
                    OrderDomainModel.PaymentMethod.PIX && (
                    <DescriptionMolecule>
                      Realize o pagamento via pix através do QR Code ou código
                      pix.
                    </DescriptionMolecule>
                  )}

                  <DescriptionMolecule bold>
                    O pagamento pode levar até 15 minutos para ser confirmado.
                  </DescriptionMolecule>

                  <ProgressBar indeterminate />
                </ContentMolecule>
              </View>
            )}

            {paymentConfirmed && (
              <View>
                <ContentMolecule>
                  <DescriptionMolecule>
                    Pagamento realizado com sucesso!
                  </DescriptionMolecule>
                </ContentMolecule>
              </View>
            )}

            <ContentMolecule>
              <Button
                onPress={() => {
                  navigation
                    .getParent<NavigationProp<RootStackParamList>>()
                    .navigate("Dashboard");
                }}
              >
                Ir para meus pedidos
              </Button>
            </ContentMolecule>
          </View>
        )}
      </View>
    </>
  );
}
