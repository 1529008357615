import { StyleProp, ViewStyle } from "react-native/types";

const container: StyleProp<ViewStyle> = {
  width: "70%",
  display: "flex",
  margin: "auto",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
};

const actions: StyleProp<ViewStyle> = {
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  gap: 20,
};

export default { container, actions };
