export namespace PaymentInfoModel {
  export interface Api {
    data: PaymentData;
    paymentMethod: PaymentMethod;
  }

  export enum PaymentMethod {
    PIX = "PIX",
    CREDIT_CARD = "CREDIT_CARD",
  }

  type PaymentData = PixPayment | CreditCardPayment;

  interface Payment {
    gatewayId: string;
    paymentDateTime?: string;
  }

  export interface PixPayment extends Payment {
    qrcode: string;
    qrCodeId: string;
    textCode: string;
  }

  interface CreditCardPayment extends Payment {
    encryptedCard: string;
    holderName: string;
    holderDocument: string;
  }
}
