import * as React from "react";
import { Dimensions, Pressable, ScrollView } from "react-native";
import {
  Button,
  Card,
  Dialog,
  Portal,
  Text,
  useTheme,
} from "react-native-paper";
import RenderHTML from "react-native-render-html";
import CurrencyMolecule from "../../molecules/currency/currency.component";
import { BadgeCardElementModel } from "./badge-element-card.model";
import styles from "./badge-element-card.styles";

export default function BadgeCardElementOrganism(
  props: BadgeCardElementModel.Api
) {
  const theme = useTheme();

  const [visibleDetailsDialog, setVisibleDetailsDialog] = React.useState(false);

  const hideDetailsDialog = () => {
    setVisibleDetailsDialog(false);
  };

  return (
    <Card style={styles.w100}>
      <Card.Title
        title={`${props.cardElement.title} ${
          props.selected ? "(Selecionado)" : ""
        }`}
        subtitle={props.cardElement.subTitle}
      />
      <Card.Cover
        source={{ uri: props.cardElement.thumbUri }}
        resizeMode="center"
      />

      <Card.Content style={{ marginTop: 10 }}>
        <Text variant="bodyLarge">{props.cardElement.descriptionTitle}</Text>

        <Text variant="bodyMedium">{props.cardElement.description}</Text>

        {props.cardElement.additionalInformation && (
          <>
            <Pressable
              onPress={() => {
                setVisibleDetailsDialog(true);
              }}
              style={{ alignSelf: "flex-start" }}
            >
              <Text style={{ marginTop: 10, color: theme.colors.primary }}>
                Saiba mais
              </Text>
            </Pressable>

            <Portal>
              <Dialog
                visible={visibleDetailsDialog}
                onDismiss={hideDetailsDialog}
              >
                <Dialog.Title>Mais informações</Dialog.Title>

                <Dialog.Content>
                  <ScrollView
                    style={{ maxHeight: Dimensions.get("window").height * 0.7 }}
                  >
                    <RenderHTML
                      contentWidth={Dimensions.get("window").width * 0.7}
                      source={{
                        html: props.cardElement.additionalInformation || "",
                      }}
                    />
                  </ScrollView>
                </Dialog.Content>

                <Dialog.Actions>
                  <Button
                    onPress={() => {
                      hideDetailsDialog();
                    }}
                  >
                    Fechar
                  </Button>
                </Dialog.Actions>
              </Dialog>
            </Portal>
          </>
        )}
      </Card.Content>

      <Card.Actions>
        <Button mode={props.selected ? "elevated" : "text"}>
          + <CurrencyMolecule>{props.cardElement.cost}</CurrencyMolecule>
        </Button>
        <Button
          onPress={() => {
            props.selectedCard(props.cardElement);
          }}
        >
          Selecionar
        </Button>
      </Card.Actions>
    </Card>
  );
}
