import AsyncStorage from "@react-native-async-storage/async-storage";
import { RouteProp, useNavigationState } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import React, { useContext } from "react";
import { View } from "react-native";
import { Button, Dialog, Portal, Text } from "react-native-paper";
import { useSelector } from "react-redux";
import CurrencyMolecule from "../../../../components/molecules/currency/currency.component";
import DescriptionMolecule from "../../../../components/molecules/description/description.component";
import { StylesContext } from "../../../../context/styles/styles.context";
import { GoogleLoginComponent } from "../../../../dev-kit/components/google-login/google-login.component";
import { snackBarSubject } from "../../../../dev-kit/snackbar.service";
import { TokenService } from "../../../../dev-kit/token.service";
import { AuthDomainModel } from "../../../../domain/auth/auth.domain.model";
import { BadgeDomainModel } from "../../../../domain/badge/badge.domain.model";
import { NewOrderStoreSelectors } from "../../../../domain/new-order/store/new-order.store.selectors";
import { RootStackParamList } from "../../../../navigation/navigation.model";
import styles from "./cost-summary.styles";

export function CostSummaryComponent(props: {
  navigation: NativeStackNavigationProp<RootStackParamList>;
  route: RouteProp<RootStackParamList>;
}) {
  const contextStyles = useContext(StylesContext);

  const navigationState = useNavigationState((state) => state);

  const [totalCost, setTotalCost] = React.useState(0);
  const [loginDialogVisible, setLoginDialogVisible] = React.useState(false);

  const selected = useSelector(NewOrderStoreSelectors.selectSelected);
  const badgeList = useSelector(NewOrderStoreSelectors.selectBadgeList);
  const inOrderAdditionalProducts = useSelector(
    NewOrderStoreSelectors.selectInOrderAdditionalProducts
  );

  React.useEffect(() => {
    const cardTypeCost = selected?.cardType?.cost || 0;
    const frontTemplateCost = selected?.frontTemplate?.cost || 0;
    const backTemplateCost = selected?.backTemplate?.cost || 0;
    setTotalCost(cardTypeCost + frontTemplateCost + backTemplateCost);
  }, [selected]);

  const ListCost = (props: { badgeList: Array<BadgeDomainModel.Badge> }) => {
    if (badgeList.length) {
      return (
        <DescriptionMolecule>
          x{badgeList.length}:{" "}
          <CurrencyMolecule>{totalCost * badgeList.length}</CurrencyMolecule>
        </DescriptionMolecule>
      );
    }
    return <></>;
  };

  const AdditionalProductsRoute = "AdditionalProducts";

  const isIsAdditionalProductsRoute = (): boolean => {
    const level0routes = navigationState.routes;
    const level1routes = level0routes[level0routes.length - 1].state?.routes;

    if (!level1routes) {
      return false;
    }

    const currentLevel1Route = level1routes[level1routes.length - 1].name;
    return currentLevel1Route === AdditionalProductsRoute;
  };

  const checkAdditionalProductsAreSet = (): boolean => {
    let isSet = true;

    for (let additionalProduct of inOrderAdditionalProducts) {
      for (let optionGroup of additionalProduct.product.optionGroups) {
        const optionIsSet = additionalProduct.selectedOptions.find(
          (selectedOption) => selectedOption.key === optionGroup.key
        )?.value;
        if (!optionIsSet) {
          isSet = false;
        }
      }
    }

    return isSet;
  };

  const checkout = async () => {
    if (!isIsAdditionalProductsRoute()) {
      props.navigation.navigate("NewOrder", {
        screen: AdditionalProductsRoute,
      });
      return;
    }

    if (!checkAdditionalProductsAreSet()) {
      snackBarSubject.next(
        "É preciso selecionar todas as opções dos produtos selecionados."
      );
      return;
    }

    const email = await TokenService.getPayloadValue<string>("email");
    const isExpired = await TokenService.isTokenExpired();

    if (email && !isExpired) {
      props.navigation.navigate("Checkout");
      return;
    }

    await AsyncStorage.setItem(
      AuthDomainModel.RouteAfterLoginStorageKey,
      "Checkout"
    );
    setLoginDialogVisible(true);
  };

  return (
    <>
      <View style={styles.summaryBar}>
        <View>
          <DescriptionMolecule>
            Unidade crachá: <CurrencyMolecule>{totalCost}</CurrencyMolecule>
          </DescriptionMolecule>
          <ListCost badgeList={badgeList}></ListCost>
        </View>

        <View>
          <Button
            disabled={
              !selected?.cardType ||
              !selected.frontTemplate ||
              !badgeList.length
            }
            mode="contained"
            onPress={checkout}
            icon={"check"}
          >
            Fechar pedido
          </Button>
        </View>
      </View>

      <Portal>
        <Dialog
          style={contextStyles.dialogStyle}
          visible={loginDialogVisible}
          onDismiss={() => {
            setLoginDialogVisible(false);
          }}
        >
          <Dialog.Title>Fazer login</Dialog.Title>
          <Dialog.Content>
            <Text variant="bodyMedium">
              Para finalizar o pedido, é preciso fazer login.
            </Text>
          </Dialog.Content>
          <Dialog.Actions>
            <GoogleLoginComponent
              onPress={() => {
                setLoginDialogVisible(false);
              }}
            />
          </Dialog.Actions>
        </Dialog>
      </Portal>
    </>
  );
}
