import AsyncStorage from "@react-native-async-storage/async-storage";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import React from "react";
import { useDispatch } from "react-redux";
import ContentMolecule from "../../components/molecules/content/content.component";
import DescriptionMolecule from "../../components/molecules/description/description.component";
import PageTitleMolecule from "../../components/molecules/page-title/page-title.component";
import SignupInfoFormOrganism from "../../components/organisms/signup-info-form/signup-info-form.component";
import { SignupInfoFormModel } from "../../components/organisms/signup-info-form/signup-info-form.model";
import { DeviceScreenContentComponent } from "../../dev-kit/components/device-screen-content/device-screen-content.component";
import { TokenService } from "../../dev-kit/token.service";
import { AuthDomainModel } from "../../domain/auth/auth.domain.model";
import { AppDispatch } from "../../domain/store";
import { UserStoreActions } from "../../domain/user/store/user.store.actions";
import { RootStackParamList } from "../../navigation/navigation.model";

type Props = NativeStackScreenProps<RootStackParamList, "NewUser">;

export function NewUser({ navigation }: Props) {
  const dispatch = useDispatch<AppDispatch>();
  const [email, setEmail] = React.useState("");

  React.useEffect(() => {
    TokenService.getPayloadValue<string>("email").then((email) => {
      setEmail(email || "");
    });
  }, []);

  const createUser = (user: SignupInfoFormModel.UserInfo) => {
    const userInfo = dispatch(
      UserStoreActions.createUser({ ...user, email })
    ).unwrap();
    userInfo.then(async () => {
      const route = await AsyncStorage.getItem(
        AuthDomainModel.RouteAfterLoginStorageKey
      );
      navigation.reset({
        index: 0,
        routes: [
          { name: "Home" },
          { name: (route as keyof RootStackParamList) || "Dashboard" },
        ],
      });
    });
  };

  return (
    <DeviceScreenContentComponent>
      <ContentMolecule>
        <PageTitleMolecule>Seja bem-vindo!</PageTitleMolecule>

        <DescriptionMolecule>
          Precisamos saber algumas informações suas:
        </DescriptionMolecule>

        <DescriptionMolecule>Email: {email}</DescriptionMolecule>

        <SignupInfoFormOrganism
          formSubmit={createUser}
        ></SignupInfoFormOrganism>
      </ContentMolecule>
    </DeviceScreenContentComponent>
  );
}
