import axios, { AxiosError } from "axios";
import { variables } from "../variables/";
import { snackBarSubject } from "./snackbar.service";
import { TokenService } from "./token.service";
import { _navigator } from "../navigation/navigation.service";
import { CommonActions } from "@react-navigation/native";

axios.interceptors.request.use(async function (config) {
  if (config.url?.includes(variables.cognito.authAddress)) {
    return config;
  }

  const tokens = await TokenService.getTokens();

  config.headers.Authorization = "Bearer " + tokens?.id_token;
  return config;
});

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error: AxiosError) {
    if (error.response?.status === 401) {
      _navigator.dispatch(
        CommonActions.reset({
          index: 0,
          routes: [{ name: "Home" }],
        })
      );

      await TokenService.removeAuthTokens();

      snackBarSubject.next("Não autorizado. Por favor, faça login.");
      return Promise.reject(error);
    }

    if (typeof error.response?.data === "object") {
      snackBarSubject.next(
        JSON.stringify(
          (error.response?.data as any)?.validationError || error.response?.data
        ).replaceAll('"', "")
      );
    }

    if (typeof error.response?.data === "string") {
      snackBarSubject.next(error.response?.data);
    }
    return Promise.reject(error);
  }
);
