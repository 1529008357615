import AsyncStorage from "@react-native-async-storage/async-storage";
import React from "react";
import { useDispatch } from "react-redux";
import { BehaviorSubject, Subject } from "rxjs";
import "./dev-kit/http.interceptor";
import { storeKey as NewOrderStoreKey } from "./domain/new-order/store/new-order.store";
import { NewOrderStoreActions } from "./domain/new-order/store/new-order.store.actions";
import { NewOrderStoreModel } from "./domain/new-order/store/new-order.store.model";
import { AppDispatch } from "./domain/store";

export const AsyncStorageReduxLoaded = new BehaviorSubject<boolean>(false);

export default function AsyncStorageReduxLoader() {
  const dispatch = useDispatch<AppDispatch>();
  React.useEffect(() => {
    loadOrderFromStorage();
  });

  const loadOrderFromStorage = () => {
    AsyncStorage.getItem(NewOrderStoreKey).then(async (data) => {
      const parsedData = JSON.parse(
        data || "{}"
      ) as Partial<NewOrderStoreModel.StoreModel>;

      parsedData.badgeList &&
        (await dispatch(
          NewOrderStoreActions.setBadgeList(parsedData.badgeList)
        ));

      parsedData.frontLogo &&
        (await dispatch(
          NewOrderStoreActions.setFrontLogo(parsedData.frontLogo)
        ));

      parsedData.selected &&
        (await dispatch(NewOrderStoreActions.setSelected(parsedData.selected)));

      parsedData.deliveryAddress &&
        (await dispatch(
          NewOrderStoreActions.setDeliveryAddress(parsedData.deliveryAddress)
        ));

      parsedData.inOrderAdditionalProductList &&
        (await dispatch(
          NewOrderStoreActions.setInOrderAdditionalProductList(
            parsedData.inOrderAdditionalProductList
          )
        ));

      AsyncStorageReduxLoaded.next(true);
    });
  };

  return <></>;
}
