import * as React from "react";
import { View } from "react-native";
import { TextInputMask, TextInputMaskProps } from "react-native-masked-text";
import { Button, TextInput } from "react-native-paper";
import { OptionsSelectorMoleculeComponent } from "../../molecules/options-selector/options-selector.component";
import { AddressFormModel } from "./address-form.model";
import styles from "./address-form.styles";
import { brstates } from "./br-states";

export default function AddressFormOrganism(props: AddressFormModel.Api) {
  const [zipCode, setZipCode] = React.useState("");
  const [street, setStreet] = React.useState("");
  const [houseNumber, setHouseNumber] = React.useState("");
  const [district, setDistrict] = React.useState("");
  const [city, setCity] = React.useState("");
  const [state, setState] = React.useState("");
  const [reference, setReference] = React.useState("");
  const [additionalInfo, setAdditionalInfo] = React.useState("");

  React.useEffect(() => {
    if (!props.address) {
      return;
    }
    setZipCode(props.address.zipCode);
    setStreet(props.address.street);
    setHouseNumber(props.address.houseNumber);
    setDistrict(props.address.district);
    setCity(props.address.city);
    setState(props.address.state);
    setReference(props.address.reference);
    setAdditionalInfo(props.address.additionalInfo);
  }, [props.address]);

  const handleZipCodeChange = (zipCode: string) => {
    setZipCode(zipCode);
    props.zipCodeChanged(zipCode);
  };

  const isFormValid = (): boolean => {
    return (
      !!zipCode &&
      zipCode.length === 8 &&
      !!street &&
      !!houseNumber &&
      !!district &&
      !!city &&
      !!state
    );
  };

  const submitForm = () => {
    props.formSubmit({
      zipCode,
      street,
      houseNumber,
      district,
      city,
      state,
      reference,
      additionalInfo,
    });
  };

  return (
    <View style={styles.w100}>
      <View style={styles.form}>
        <TextInput
          style={styles.w100}
          value={zipCode}
          onChangeText={handleZipCodeChange}
          mode="flat"
          label="CEP"
          maxLength={8}
          render={(props) => {
            const maskProps = {
              ...props,
              type: "only-numbers",
            } as TextInputMaskProps;
            return <TextInputMask {...maskProps} />;
          }}
        />

        <TextInput
          style={styles.w100}
          value={street}
          onChangeText={setStreet}
          mode="flat"
          label="Rua"
          placeholder="Rua"
        />

        <TextInput
          style={styles.w100}
          value={houseNumber}
          onChangeText={setHouseNumber}
          mode="flat"
          label="Número"
        />

        <TextInput
          style={styles.w100}
          value={district}
          onChangeText={setDistrict}
          mode="flat"
          label="Bairro"
        />

        <OptionsSelectorMoleculeComponent
          dialogStyles={props.dialogStyles}
          label="Estado"
          options={brstates}
          value={state}
          onOptionChange={setState}
        ></OptionsSelectorMoleculeComponent>

        <TextInput
          style={styles.w100}
          value={city}
          onChangeText={setCity}
          mode="flat"
          label="Cidade"
        />

        <TextInput
          style={styles.w100}
          value={reference}
          onChangeText={setReference}
          mode="flat"
          label="Ponto de referência"
        />

        <TextInput
          style={styles.w100}
          value={additionalInfo}
          onChangeText={setAdditionalInfo}
          mode="flat"
          label="Informações adicionais"
        />

        <Button
          icon="check"
          disabled={!isFormValid()}
          mode="contained"
          onPress={submitForm}
        >
          Cadastrar novo endereço e Fechar Pedido
        </Button>
      </View>
    </View>
  );
}
