import { PropsWithChildren, createContext } from "react";
import { Devices, useDevice } from "../../hooks/use-device";
import { StylesContextModel } from "./styles.context.model";

const initialValue: StylesContextModel = { dialogStyle: {} };

export const StylesContextProvider = (props: PropsWithChildren) => {
  const device = useDevice();

  const value: StylesContextModel = { dialogStyle: {} };

  deviceSetters[device](value);

  return (
    <StylesContext.Provider value={value}>
      {props.children}
    </StylesContext.Provider>
  );
};

const deviceSetters: Record<Devices, (styles: StylesContextModel) => void> = {
  DESKTOP: (styles) => {
    styles.dialogStyle = { width: 500, alignSelf: "center" };
  },
  TABLET_MOBILE: () => {},
};

export const StylesContext = createContext<StylesContextModel>(initialValue);
