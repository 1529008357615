import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash-es";
import { NewOrderStoreActions } from "./new-order.store.actions";
import { NewOrderStoreModel } from "./new-order.store.model";

export const storeKey = "new-order";
export const store = createSlice({
  name: storeKey,
  initialState: NewOrderStoreModel.initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      NewOrderStoreActions.getCardTypes.fulfilled,
      (state, action) => {
        state.cardTypeList = action.payload;
      }
    );

    builder.addCase(
      NewOrderStoreActions.getFrontTemplateList.fulfilled,
      (state, action) => {
        state.frontTemplateList = action.payload;
      }
    );

    builder.addCase(
      NewOrderStoreActions.getBackTemplateList.fulfilled,
      (state, action) => {
        state.backTemplateList = action.payload;
      }
    );

    builder.addCase(
      NewOrderStoreActions.getAdditionalProducts.fulfilled,
      (state, action) => {
        state.additionalProductsList = action.payload;
      }
    );

    builder.addCase(
      NewOrderStoreActions.upsertInOrderAdditionalProductList.fulfilled,
      (state, action) => {
        if (!action.payload.quantity) {
          state.inOrderAdditionalProductList =
            state.inOrderAdditionalProductList.filter(
              (el) => el.product.id !== action.payload.product.id
            );
          return;
        }

        const indexToChange = state.inOrderAdditionalProductList.findIndex(
          (inOrderAdditionalProduct) =>
            inOrderAdditionalProduct.product.id === action.payload.product.id
        );

        if (indexToChange !== -1) {
          state.inOrderAdditionalProductList[indexToChange] = action.payload;
          return;
        }

        state.inOrderAdditionalProductList.push(action.payload);
      }
    );

    builder.addCase(
      NewOrderStoreActions.setInOrderAdditionalProductList.fulfilled,
      (state, action) => {
        state.inOrderAdditionalProductList = action.payload;
      }
    );

    builder.addCase(
      NewOrderStoreActions.setSelected.fulfilled,
      (state, action) => {
        state.selected = action.payload;
      }
    );

    builder.addCase(
      NewOrderStoreActions.setBadgeList.fulfilled,
      (state, action) => {
        state.badgeList = action.payload;
      }
    );

    builder.addCase(
      NewOrderStoreActions.setSelectedCardType.fulfilled,
      (state, action) => {
        state.selected = { ...state.selected, cardType: action.payload };
      }
    );

    builder.addCase(
      NewOrderStoreActions.setSelectedFrontTemplate.fulfilled,
      (state, action) => {
        state.selected = { ...state.selected, frontTemplate: action.payload };
      }
    );

    builder.addCase(
      NewOrderStoreActions.setSelectedBackTemplate.fulfilled,
      (state, action) => {
        state.selected = { ...state.selected, backTemplate: action.payload };
      }
    );

    builder.addCase(
      NewOrderStoreActions.resetNewOrder.fulfilled,
      (state, action) => {
        state = { ...NewOrderStoreModel.initialState };
        return state;
      }
    );

    builder.addCase(
      NewOrderStoreActions.setFrontLogo.fulfilled,
      (state, action) => {
        state.frontLogo = action.payload;
      }
    );

    builder.addCase(
      NewOrderStoreActions.setUpsertingBadge.fulfilled,
      (state, action) => {
        state.upsertingBadge = action.payload;
      }
    );

    builder.addCase(
      NewOrderStoreActions.appendBadge.fulfilled,
      (state, action) => {
        if (!state.upsertingBadge) {
          return;
        }
        const newBadge = cloneDeep(state.upsertingBadge);
        state.badgeList = [...state.badgeList, newBadge];
        state.upsertingBadge = undefined;
      }
    );

    builder.addCase(
      NewOrderStoreActions.updateBadge.fulfilled,
      (state, action) => {
        if (!state.upsertingBadge) {
          return;
        }
        const newBadge = cloneDeep(state.upsertingBadge);
        state.badgeList[action.payload] = newBadge;
        state.upsertingBadge = undefined;
      }
    );

    builder.addCase(
      NewOrderStoreActions.deleteBadge.fulfilled,
      (state, action) => {
        state.badgeList = state.badgeList.filter(
          (item, index) => index !== action.payload
        );
      }
    );

    builder.addCase(
      NewOrderStoreActions.setPaymentMethod.fulfilled,
      (state, action) => {
        state.paymentMethod = action.payload;
      }
    );

    builder.addCase(
      NewOrderStoreActions.setPaymentData.fulfilled,
      (state, action) => {
        state.paymentData = action.payload;
      }
    );

    builder.addCase(
      NewOrderStoreActions.setDeliveryAddress.fulfilled,
      (state, action) => {
        state.deliveryAddress = action.payload;
      }
    );

    builder.addCase(
      NewOrderStoreActions.getFreightInfo.fulfilled,
      (state, action) => {
        state.freightCost = Number(action.payload.cost);
        state.deliveryTime = Number(action.payload.deliveryTime);
      }
    );

    builder.addCase(
      NewOrderStoreActions.setReviewScreenshot.fulfilled,
      (state, action) => {
        state.reviewScreenshot = action.payload;
      }
    );
  },
});

export default store.reducer;
