import {
  NativeStackScreenProps,
  createNativeStackNavigator,
} from "@react-navigation/native-stack";
import React from "react";
import { View } from "react-native";
import { NewOrderStackParamList } from "../../new-order.navigation.model";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../domain/store";
import { Front } from "./front/front.page";
import { Back } from "./back/back.page";
import { BadgeDomainModel } from "../../../../domain/badge/badge.domain.model";
import { cloneDeep } from "lodash-es";
import { NewOrderStoreSelectors } from "../../../../domain/new-order/store/new-order.store.selectors";
import { NewOrderStoreActions } from "../../../../domain/new-order/store/new-order.store.actions";
import { Button, SegmentedButtons, Surface } from "react-native-paper";
import ContentMolecule from "../../../../components/molecules/content/content.component";
import styles from "./upsert-badge.styles";

type Props = NativeStackScreenProps<NewOrderStackParamList, "UpsertBadge">;

export function UpsertBadge({ navigation, route }: Props) {
  const [side, setSide] = React.useState("front");
  const dispatch = useDispatch<AppDispatch>();
  const selected = useSelector(NewOrderStoreSelectors.selectSelected);
  const badgeList = useSelector(NewOrderStoreSelectors.selectBadgeList);

  React.useEffect(() => {
    if (!selected || !badgeList || route.params?.upserting) {
      return;
    }
    initializeBadge();
  }, [selected, badgeList, route?.params?.updateIndex]);

  const initializeBadge = () => {
    const newBadge = createBadge();
    const updateIndex = route?.params?.updateIndex;

    if (updateIndex != null && updateIndex < badgeList.length) {
      const existingBadge = badgeList[updateIndex];
      injectExistingValues(newBadge, existingBadge);
    }

    dispatch(NewOrderStoreActions.setUpsertingBadge(newBadge));
  };

  const createBadge = (): BadgeDomainModel.Badge => {
    const newBadge: BadgeDomainModel.Badge = {
      front: {
        fields: cloneDeep(selected?.frontTemplate?.fields || []),
      },
    };

    if (selected?.backTemplate) {
      newBadge.back = {
        fields: cloneDeep(selected.backTemplate?.fields || []),
      };
    }

    return newBadge;
  };

  const injectExistingValues = (
    newBadge: BadgeDomainModel.Badge,
    existingBadge: BadgeDomainModel.Badge
  ) => {
    newBadge.front.profileUri = existingBadge.front.profileUri;
    newBadge.front.fields = cloneDeep(existingBadge.front?.fields || []);
    if (newBadge.back) {
      newBadge.back.fields = cloneDeep(existingBadge.back?.fields || []);
    }
  };

  const confirmUpsert = () => {
    const updateIndex = route?.params?.updateIndex;
    if (updateIndex != null && updateIndex < badgeList.length) {
      dispatch(NewOrderStoreActions.updateBadge(updateIndex));
    } else {
      dispatch(NewOrderStoreActions.appendBadge());
    }

    navigation.navigate("BadgeList");
  };

  return (
    <>
      <Surface style={{ zIndex: 1 }} elevation={3}>
        <ContentMolecule>
          <View style={styles.actions}>
            <SegmentedButtons
              value={side}
              onValueChange={(side) => {
                setSide(side);
              }}
              buttons={[
                {
                  value: "front",
                  label: "Frente",
                },
                {
                  value: "back",
                  label: "Verso",
                  disabled: !selected?.backTemplate,
                },
              ]}
            />

            <Button icon="check" mode="contained" onPress={confirmUpsert}>
              Finalizar
            </Button>
          </View>
        </ContentMolecule>
      </Surface>

      {side === "front" && <Front />}
      {side === "back" && <Back />}
    </>
  );
}
