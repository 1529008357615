import { GlobalStoreModel } from "../../store";

export namespace NewOrderStoreSelectors {
  export const selectCardTypes = (state: GlobalStoreModel) =>
    state["new-order"].cardTypeList;

  export const selectFrontTemplateList = (state: GlobalStoreModel) =>
    state["new-order"].frontTemplateList;

  export const selectBackTemplateList = (state: GlobalStoreModel) =>
    state["new-order"].backTemplateList;

  export const selectAdditionalProducts = (state: GlobalStoreModel) =>
    state["new-order"].additionalProductsList;

  export const selectInOrderAdditionalProducts = (state: GlobalStoreModel) =>
    state["new-order"].inOrderAdditionalProductList;

  export const selectSelected = (state: GlobalStoreModel) =>
    state["new-order"].selected;

  export const selectBadgeList = (state: GlobalStoreModel) =>
    state["new-order"].badgeList;

  export const selectFrontLogo = (state: GlobalStoreModel) =>
    state["new-order"].frontLogo;

  export const selectUpsertingBadge = (state: GlobalStoreModel) =>
    state["new-order"].upsertingBadge;

  export const selectFreightCost = (state: GlobalStoreModel) =>
    state["new-order"].freightCost;

  export const selectDeliveryTime = (state: GlobalStoreModel) =>
    state["new-order"].deliveryTime;

  export const selectDeliveryAddress = (state: GlobalStoreModel) => {
    return state["new-order"].deliveryAddress;
  };

  export const selectPaymentMethod = (state: GlobalStoreModel) =>
    state["new-order"].paymentMethod;
}
