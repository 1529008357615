import { UserDomainModel } from "../../../domain/user/user.domain.model";

export namespace UserStoreModel {
  export interface StoreModel {
    user?: UserDomainModel.User;
    userAddressList: Array<UserDomainModel.Address>;
  }

  export const initialState: StoreModel = {
    userAddressList: [],
  };
}
