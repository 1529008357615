import { StyleProp, ViewStyle } from "react-native/types";

const form: StyleProp<ViewStyle> = { display: "flex", gap: 10 };
const w100: StyleProp<ViewStyle> = { width: "100%" };

const styles = {
  w100,
  form,
};

export default styles;
