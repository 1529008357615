import { Divider, IconButton, Menu } from "react-native-paper";
import React from "react";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { RootStackParamList } from "../navigation.model";
import { TokenService } from "../../dev-kit/token.service";

export function NavigationMenuComponent(props: {}) {
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();
  const [visible, setVisible] = React.useState(false);
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [email, setEmail] = React.useState<string | undefined>("");

  const openMenu = () => setVisible(true);
  const closeMenu = () => setVisible(false);

  React.useEffect(() => {
    TokenService.getPayloadValue<string>("email").then((email) => {
      setIsLoggedIn(!!email);
      setEmail(email);
    });
  }, [visible]);
  return (
    <Menu
      visible={visible}
      anchorPosition="top"
      onDismiss={closeMenu}
      anchor={
        <IconButton
          icon={"account-circle-outline"}
          onPress={openMenu}
        ></IconButton>
      }
    >
      {email && (
        <>
          <Menu.Item disabled={true} title={email} />
          <Divider />
        </>
      )}

      <Menu.Item
        disabled={!isLoggedIn}
        onPress={() => {
          navigation.navigate("Dashboard");
        }}
        title="Meus pedidos"
      />
      <Divider />
      <Menu.Item
        disabled={!isLoggedIn}
        onPress={() => {
          TokenService.removeAuthTokens().then(() => {
            navigation.reset({
              index: 0,
              routes: [{ name: "Home" }],
            });
          });
        }}
        title="Logout"
      />
    </Menu>
  );
}
