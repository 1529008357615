import axios, { AxiosResponse } from "axios";
import { from, map, Observable } from "rxjs";
import { HttpService } from "../../dev-kit/http.service";
import { OrderDomainModel } from "./order.domain.model";

export class OrderHttpService extends HttpService {
  static getOrders(): Observable<Array<OrderDomainModel.Order>> {
    const request = axios.get<Array<OrderDomainModel.Order>>(
      `${this.backendAddress}/orders/self`
    );

    const observable: Observable<AxiosResponse<Array<OrderDomainModel.Order>>> =
      from(request);

    return observable.pipe(map((result) => result.data));
  }

  static getOrder(orderId: string): Observable<OrderDomainModel.Order> {
    const request = axios.get<OrderDomainModel.Order>(
      `${this.backendAddress}/orders/order/?id=${orderId}`
    );

    const observable: Observable<AxiosResponse<OrderDomainModel.Order>> =
      from(request);

    return observable.pipe(map((result) => result.data));
  }

  static createOrder(
    order: OrderDomainModel.Order
  ): Observable<OrderDomainModel.Order> {
    const request = axios.post<OrderDomainModel.Order>(
      `${this.backendAddress}/orders/create-order`,
      order
    );

    const observable: Observable<AxiosResponse<OrderDomainModel.Order>> =
      from(request);

    return observable.pipe(map((result) => result.data));
  }

  static uploadOrderImage(payload: {
    base64image: string;
    orderId: string;
    fileName: string;
  }) {
    const request = axios.post<void>(
      `${this.backendAddress}/orders/upload-order-image`,
      payload
    );

    const observable: Observable<AxiosResponse<void>> = from(request);

    return observable.pipe(map((result) => result.data));
  }

  static confirmOrderValidation(
    orderId: string
  ): Observable<OrderDomainModel.Order> {
    const request = axios.post<OrderDomainModel.Order>(
      `${this.backendAddress}/orders/confirm-order-validation/?id=${orderId}`
    );

    const observable: Observable<AxiosResponse<OrderDomainModel.Order>> =
      from(request);

    return observable.pipe(map((result) => result.data));
  }
}
