import { StyleProp, ViewStyle } from "react-native/types";

const pictureButtons: StyleProp<ViewStyle> = {
  display: "flex",
  gap: 10,
  flexDirection: "row",
  justifyContent: "center",
};

export default { pictureButtons };
