import * as React from 'react';
import { Image, View } from 'react-native';
import { List, TouchableRipple } from 'react-native-paper';
import { AddressItemModel } from './address-item.model';
import styles from './address-item.styles';
import DescriptionMolecule from '../description/description.component';

export default function AddressItemMolecule(props: React.PropsWithChildren<AddressItemModel.Api>) {

    const addressTitle = () => {
        return (
            <View style={styles.addressTitle}>
                {props.children}

                <DescriptionMolecule>
                    {`${props.address.street}, ${props.address.houseNumber}`}
                </DescriptionMolecule>
            </View>
        )
    }

    return (
        <View style={styles.w100}>
            <TouchableRipple onPress={() => { props.selectedAddress(props.address) }}>
                <List.Item
                    title={addressTitle()}
                    description={`CEP: ${props.address.zipCode} - ${props.address.district} - ${props.address.city} - ${props.address.state}`}
                />
            </TouchableRipple>
        </View>
    );
};
