import React from 'react';
import { Snackbar } from "react-native-paper";
import { Subject } from 'rxjs';

export const snackBarSubject = new Subject<string>();

export function SnackbarService() {
    const [visible, setVisible] = React.useState(false);
    const [snackMessage, setSnackMessage] = React.useState('');

    snackBarSubject.subscribe((message) => {
        setVisible(true);
        setSnackMessage(message);
    })

    return (
        <Snackbar
            onDismiss={() => { setVisible(false) }}
            visible={visible}
            duration={3000}
        >
            {snackMessage}
        </Snackbar>
    )
}