import { Image, View, Text } from "react-native";
import { CardBackModel } from "./card-back.model";
import styles, { cardDimensions } from "./card-back.styles";

export default function CardBackOrganism(props: CardBackModel.Api) {
  const fields = (fields: Array<CardBackModel.Field>) => {
    return fields.map((field, index) => {
      return (
        <View
          key={index}
          style={{
            position: "absolute",
            top: cardDimensions.height * field.top,
            left: cardDimensions.width * field.left,
          }}
        >
          <Text
            style={{
              color: field.fontColor,
              fontFamily: "IRoboto",
              fontSize: cardDimensions.height * field.fontSize,
            }}
          >
            {field.value}
          </Text>
        </View>
      );
    });
  };

  return (
    <View style={styles.borderBox}>
      <View style={styles.card}>
        <Image
          style={styles.backGround}
          source={{ uri: props.backgroundUri }}
        />
        {fields(props.fields)}
      </View>
    </View>
  );
}
