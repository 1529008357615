import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { View } from "react-native";
import { Button } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import ContentMolecule from "../../../components/molecules/content/content.component";
import { NewOrderStoreActions } from "../../../domain/new-order/store/new-order.store.actions";
import { NewOrderStoreSelectors } from "../../../domain/new-order/store/new-order.store.selectors";
import { OrderDomainModel } from "../../../domain/order/order.domain.model";
import { AppDispatch } from "../../../domain/store";
import { CheckoutStackParamList } from "../checkout.navigation.model";
import { CreditCardInformationFormComponent } from "./components/credit-card-information-form/credit-card-information-form.component";
import styles from "./payment-method-selection.styles";

type Props = NativeStackScreenProps<
  CheckoutStackParamList,
  "PaymentMethodSelection"
>;

export function PaymentMethodSelection({ navigation }: Props) {
  const paymentMethodSelected = useSelector(
    NewOrderStoreSelectors.selectPaymentMethod
  );

  const dispatch = useDispatch<AppDispatch>();

  const proceed = () => {
    navigation.navigate("AddressSelection");
  };

  return (
    <>
      <ContentMolecule>
        <View style={styles.container}>
          <Button
            icon={"qrcode"}
            mode={
              paymentMethodSelected === OrderDomainModel.PaymentMethod.PIX
                ? "contained"
                : "outlined"
            }
            onPress={async () => {
              dispatch(
                NewOrderStoreActions.setPaymentMethod(
                  OrderDomainModel.PaymentMethod.PIX
                )
              );

              const paymentData: OrderDomainModel.PixPayment = {
                gatewayId: "",
                qrcode: "",
                qrCodeId: "",
                textCode: "",
              };

              dispatch(NewOrderStoreActions.setPaymentData(paymentData)).then(
                proceed
              );
            }}
          >
            PIX
          </Button>

          <Button
            icon={"credit-card"}
            mode={
              paymentMethodSelected ===
              OrderDomainModel.PaymentMethod.CREDIT_CARD
                ? "contained"
                : "outlined"
            }
            onPress={async () => {
              dispatch(
                NewOrderStoreActions.setPaymentMethod(
                  OrderDomainModel.PaymentMethod.CREDIT_CARD
                )
              );
            }}
          >
            Cartão de crédito
          </Button>

          {paymentMethodSelected ===
            OrderDomainModel.PaymentMethod.CREDIT_CARD && (
            <CreditCardInformationFormComponent onCreditCardSubmit={proceed} />
          )}
        </View>
      </ContentMolecule>
    </>
  );
}
