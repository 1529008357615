import AsyncStorage from "@react-native-async-storage/async-storage";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import React from "react";
import { useSelector } from "react-redux";
import { TokenService } from "../../dev-kit/token.service";
import { AuthDomainModel } from "../../domain/auth/auth.domain.model";
import { NewOrderStoreSelectors } from "../../domain/new-order/store/new-order.store.selectors";
import { useDevice } from "../../hooks/use-device";
import { RootStackParamList } from "../../navigation/navigation.model";
import { HomeActionsComponentsRegistries } from "./device-components/home-actions-component/registries";

type Props = NativeStackScreenProps<RootStackParamList, "Home">;

export function Home({ navigation, route }: Props) {
  const device = useDevice();
  const newOrderBadges = useSelector(NewOrderStoreSelectors.selectBadgeList);
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const newOrder = () => {
    navigation.navigate("NewOrder", {
      screen: newOrderBadges.length ? "BadgeList" : "CardType",
    });
  };

  React.useEffect(() => {
    if (route.name !== "Home") {
      return;
    }

    TokenService.getPayloadValue<string>("email").then((email) => {
      if (email) {
        setIsLoggedIn(true);
      }
    });
  }, [route]);

  const HomeComponent = HomeActionsComponentsRegistries[device];

  return (
    <HomeComponent
      isLoggedIn={isLoggedIn}
      newOrderBadgesLenght={newOrderBadges.length}
      onLoginPress={() => {
        AsyncStorage.removeItem(AuthDomainModel.RouteAfterLoginStorageKey);
      }}
      onNewOrderPress={newOrder}
      onSelfOrdersListPress={() => {
        navigation.navigate("Dashboard");
      }}
    />
  );
}
