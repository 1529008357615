export namespace AuthDomainModel {
  export const RouteAfterLoginStorageKey = "routeAfterLogin";

  export interface CognitoTokenResponse {
    id_token: string;
    access_token: string;
    refresh_token: string;
    expires_in: number;
    token_type: string;
  }
}
