import * as React from "react";
import { FlatList, Image, SafeAreaView, View } from "react-native";
import { List, TouchableRipple } from "react-native-paper";
import { OrderListModel } from "./order-list.model";
import styles from "./order-list.styles";

export default function OrderListOrganism(props: OrderListModel.Api) {
  const renderItem = ({ item }: { item: OrderListModel.Order }) => {
    return (
      <TouchableRipple
        onPress={() => {
          props.selectedOrder(item);
        }}
      >
        <List.Item
          title={`# ${item.id}`}
          description={`${new Date(item.date).toLocaleDateString()}`}
          right={(props) => <View style={styles.itemIcon}></View>}
        />
      </TouchableRipple>
    );
  };

  return (
    <SafeAreaView style={styles.container}>
      <FlatList
        data={props.orderList}
        renderItem={renderItem}
        keyExtractor={(item, index) => String(index)}
      />
    </SafeAreaView>
  );
}
