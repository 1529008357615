import { NativeStackScreenProps } from "@react-navigation/native-stack";
import React from "react";
import { FlatList, SafeAreaView } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import ContentMolecule from "../../../../components/molecules/content/content.component";
import BadgeCardElementOrganism from "../../../../components/organisms/badge-element-card/badge-element-card.component";
import { AppStoreActions } from "../../../../domain/app/app.store.actions";
import { BadgeDomainModel } from "../../../../domain/badge/badge.domain.model";
import { NewOrderStoreActions } from "../../../../domain/new-order/store/new-order.store.actions";
import { NewOrderStoreSelectors } from "../../../../domain/new-order/store/new-order.store.selectors";
import { AppDispatch } from "../../../../domain/store";
import { Devices, useDevice } from "../../../../hooks/use-device";
import { NewOrderStackParamList } from "../../new-order.navigation.model";
import styles from "./front-template.styles";

type Props = NativeStackScreenProps<NewOrderStackParamList, "FrontTemplate">;

export function FrontTemplate({ navigation, route }: Props) {
  const device = useDevice();

  const selected = useSelector(NewOrderStoreSelectors.selectSelected);
  const templates = useSelector(NewOrderStoreSelectors.selectFrontTemplateList);
  const dispatch = useDispatch<AppDispatch>();

  React.useEffect(() => {
    dispatch(AppStoreActions.setScreenBlockedForLoading(true));
    dispatch(
      AppStoreActions.setLoadingLabel("Carregando templates de frente...")
    );

    dispatch(NewOrderStoreActions.getFrontTemplateList()).then(() => {
      dispatch(AppStoreActions.setScreenBlockedForLoading(false));
    });
  }, []);

  const selectedTemplate = (item: BadgeDomainModel.Template) => {
    dispatch(NewOrderStoreActions.setSelectedFrontTemplate(item));
    navigation.navigate("BackTemplate");
  };

  const renderItem = ({ item }: { item: BadgeDomainModel.Template }) => {
    return (
      <ContentMolecule>
        <BadgeCardElementOrganism
          cardElement={item}
          selectedCard={() => {
            selectedTemplate(item);
          }}
          selected={selected?.frontTemplate?.id === item.id}
        ></BadgeCardElementOrganism>
      </ContentMolecule>
    );
  };

  return (
    <SafeAreaView style={styles.container}>
      <FlatList
        contentContainerStyle={
          device === Devices.DESKTOP
            ? { flexDirection: "row", flexWrap: "wrap" }
            : undefined
        }
        data={templates}
        renderItem={renderItem}
        keyExtractor={(item, index) => String(index)}
      />
    </SafeAreaView>
  );
}
