import { ScrollView, View } from "react-native";
import { ContentShellApiModel } from "../api.model";
import styles from "./styles";

export const ContentShellComponent = (props: ContentShellApiModel) => {
  return (
    <ScrollView style={styles.container}>
      {props.selectionButton}

      {props.badgePreview}

      <View style={styles.actions}>
        {props.confirmButton}

        {props.removeLogoButton}
      </View>
    </ScrollView>
  );
};
