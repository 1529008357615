import { NativeStackScreenProps } from "@react-navigation/native-stack";
import React from "react";
import { FlatList, SafeAreaView } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import ContentMolecule from "../../../../components/molecules/content/content.component";
import BadgeCardElementOrganism from "../../../../components/organisms/badge-element-card/badge-element-card.component";
import { AppStoreActions } from "../../../../domain/app/app.store.actions";
import { BadgeDomainModel } from "../../../../domain/badge/badge.domain.model";
import { NewOrderStoreActions } from "../../../../domain/new-order/store/new-order.store.actions";
import { NewOrderStoreSelectors } from "../../../../domain/new-order/store/new-order.store.selectors";
import { AppDispatch } from "../../../../domain/store";
import { Devices, useDevice } from "../../../../hooks/use-device";
import { NewOrderStackParamList } from "../../new-order.navigation.model";
import styles from "./back-template.styles";

type Props = NativeStackScreenProps<NewOrderStackParamList, "BackTemplate">;

const BLANK_CARD_ID = "BLANK_CARD_ID";

const blankCardTemplate: BadgeDomainModel.Template = {
  backgroundUri:
    "https://express.primeinfo-id.com.br/primeexpress/images/templates_thumbs/blank.jpg",
  cost: 0,
  description: "Sem impressão no verso",
  descriptionTitle: "Verso em branco",
  fields: [],
  id: BLANK_CARD_ID,
  subTitle: "Verso em branco (sem impressão)",
  thumbUri:
    "https://express.primeinfo-id.com.br/primeexpress/images/templates_thumbs/blank.jpg",
  title: "Verso em branco",
  type: "back",
};

export function BackTemplate({ navigation, route }: Props) {
  const device = useDevice();

  const selected = useSelector(NewOrderStoreSelectors.selectSelected);
  const templates = useSelector(NewOrderStoreSelectors.selectBackTemplateList);
  const dispatch = useDispatch<AppDispatch>();

  React.useEffect(() => {
    dispatch(AppStoreActions.setScreenBlockedForLoading(true));
    dispatch(
      AppStoreActions.setLoadingLabel("Carregando templates de verso...")
    );

    dispatch(NewOrderStoreActions.getBackTemplateList()).then(() => {
      dispatch(AppStoreActions.setScreenBlockedForLoading(false));
    });
  }, []);

  const selectedTemplate = (item: BadgeDomainModel.Template) => {
    dispatch(NewOrderStoreActions.setSelectedBackTemplate(item));
    moveOn();
  };

  const clearBack = () => {
    dispatch(NewOrderStoreActions.setSelectedBackTemplate());
    moveOn();
  };

  const moveOn = () => {
    navigation.navigate("SetFrontLogo");
  };

  const renderItem = ({ item }: { item: BadgeDomainModel.Template }) => {
    return (
      <ContentMolecule>
        <BadgeCardElementOrganism
          cardElement={item}
          selectedCard={() => {
            if (item.id === BLANK_CARD_ID) {
              clearBack();
              return;
            }

            selectedTemplate(item);
          }}
          selected={selected?.backTemplate?.id === item.id}
        ></BadgeCardElementOrganism>
      </ContentMolecule>
    );
  };

  return (
    <SafeAreaView style={styles.container}>
      <FlatList
        contentContainerStyle={
          device === Devices.DESKTOP
            ? { flexDirection: "row", flexWrap: "wrap" }
            : undefined
        }
        data={[blankCardTemplate, ...templates]}
        renderItem={renderItem}
        keyExtractor={(item, index) => String(index)}
      />
    </SafeAreaView>
  );
}
