import { StyleProp, ViewStyle, ImageStyle } from "react-native/types";

export const cardDimensions = { width: 300, height: 466 };

const borderBox: StyleProp<ViewStyle> = {
  alignSelf: "auto",
  borderStyle: "solid",
  borderWidth: 1,
};

const card: StyleProp<ViewStyle> = {
  ...cardDimensions,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const backGround: StyleProp<ImageStyle> = { ...cardDimensions };

const logoPicture: StyleProp<ImageStyle> = {
  position: "absolute",
  top: cardDimensions.height * 0.13,
  width: cardDimensions.width * 0.8,
  height: cardDimensions.height * 0.1286,
};

const profilePicture: StyleProp<ImageStyle> = {
  position: "absolute",
  width: cardDimensions.width * 0.5,
  height: cardDimensions.height * 0.4291,
};

const styles = { card, borderBox, backGround, profilePicture, logoPicture };

export default styles;
