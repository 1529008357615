import { createAsyncThunk } from "@reduxjs/toolkit";

export namespace AppStoreActions {
  export const setScreenBlockedForLoading = createAsyncThunk(
    `setScreenBlockedForLoading`,
    async (isScreenBlockedForLoading: boolean) => {
      return isScreenBlockedForLoading;
    }
  );

  export const setLoadingLabel = createAsyncThunk(
    `setLoadingLabel`,
    async (loadingLabel: string) => {
      return loadingLabel;
    }
  );
}
