import { createAsyncThunk } from "@reduxjs/toolkit";
import { firstValueFrom } from "rxjs";
import { BadgeDomainModel } from "../../badge/badge.domain.model";
import { OrderDomainModel } from "../../order/order.domain.model";
import { UserDomainModel } from "../../user/user.domain.model";
import { NewOrderHttpService } from "../new-order.http.service";
import { NewOrderStoreModel } from "./new-order.store.model";

export namespace NewOrderStoreActions {
  export const getCardTypes = createAsyncThunk(`getCardTypes`, async () => {
    const cardTypes = await firstValueFrom(NewOrderHttpService.getCardTypes());
    return cardTypes;
  });

  export const getFrontTemplateList = createAsyncThunk(
    `getFrontTemplateList`,
    async () => {
      const templateList = await firstValueFrom(
        NewOrderHttpService.getFrontTemplateList()
      );
      return templateList;
    }
  );

  export const getBackTemplateList = createAsyncThunk(
    `getBackTemplateList`,
    async () => {
      const templateList = await firstValueFrom(
        NewOrderHttpService.getBackTemplateList()
      );
      return templateList;
    }
  );

  export const getAdditionalProducts = createAsyncThunk(
    `getAdditionalProducts`,
    async () => {
      const additionalProductsList = await firstValueFrom(
        NewOrderHttpService.getAdditionalProducts()
      );
      return additionalProductsList;
    }
  );

  export const setSelected = createAsyncThunk(
    `setSelected`,
    async (selected: NewOrderStoreModel.Selected) => {
      return selected;
    }
  );

  export const setBadgeList = createAsyncThunk(
    `setBadgeList`,
    async (badgeList: Array<BadgeDomainModel.Badge>) => {
      return badgeList;
    }
  );

  export const setSelectedCardType = createAsyncThunk(
    `setSelectedCardType`,
    async (cardType: BadgeDomainModel.CardType) => {
      return cardType;
    }
  );

  export const setSelectedFrontTemplate = createAsyncThunk(
    `setSelectedFrontTemplate`,
    async (template: BadgeDomainModel.Template) => {
      return template;
    }
  );

  export const setSelectedBackTemplate = createAsyncThunk(
    `setSelectedBackTemplate`,
    async (template: BadgeDomainModel.Template | undefined) => {
      return template;
    }
  );

  export const upsertInOrderAdditionalProductList = createAsyncThunk(
    `upsertInOrderAdditionalProductList`,
    async (
      inOrderAdditionalProduct: OrderDomainModel.InOrderAdditionalProduct
    ) => {
      return inOrderAdditionalProduct;
    }
  );

  export const setInOrderAdditionalProductList = createAsyncThunk(
    `setInOrderAdditionalProductList`,
    async (
      inOrderAdditionalProductList: Array<OrderDomainModel.InOrderAdditionalProduct>
    ) => {
      return inOrderAdditionalProductList;
    }
  );

  export const resetNewOrder = createAsyncThunk(
    `resetSelected`,
    async () => {}
  );

  export const setFrontLogo = createAsyncThunk(
    `setFrontLogo`,
    async (logo: string | undefined) => {
      return logo;
    }
  );

  export const setUpsertingBadge = createAsyncThunk(
    `setUpsertingBadge`,
    async (upsertingBadge: BadgeDomainModel.Badge) => {
      return upsertingBadge;
    }
  );

  export const updateBadge = createAsyncThunk(
    `updateBadge`,
    async (index: number) => {
      return index;
    }
  );

  export const deleteBadge = createAsyncThunk(
    `deleteBadge`,
    async (index: number) => {
      return index;
    }
  );

  export const appendBadge = createAsyncThunk(`appendBadge`, async () => {});

  export const setPaymentMethod = createAsyncThunk(
    `setPaymentMethod`,
    async (paymentMethod: OrderDomainModel.PaymentMethod) => {
      return paymentMethod;
    }
  );

  export const setPaymentData = createAsyncThunk(
    `setPaymentData`,
    async (paymentData: OrderDomainModel.PaymentData) => {
      return paymentData;
    }
  );

  export const setDeliveryAddress = createAsyncThunk(
    `setDeliveryAddress`,
    async (addess: UserDomainModel.Address) => {
      return addess;
    }
  );

  export const getFreightInfo = createAsyncThunk(
    `getFreightCost`,
    async (zipCode: string) => {
      const cost = await firstValueFrom(
        NewOrderHttpService.getFreightCost(zipCode)
      );
      return cost;
    }
  );

  export const setReviewScreenshot = createAsyncThunk(
    `setReviewScreenshot`,
    async (screenshot: string) => {
      return screenshot;
    }
  );
}
