import React from "react";
import CardBackOrganism from "../../../components/organisms/card-back/card-back.component";
import CardFrontOrganism from "../../../components/organisms/card-front/card-front.component";
import { BadgeDomainModel } from "../../../domain/badge/badge.domain.model";
import { BadgeViewModel } from "./badge-view.model";
import { View } from "react-native";
import { SegmentedButtons } from "react-native-paper";
import ContentMolecule from "../../../components/molecules/content/content.component";

export function BadgeView(props: BadgeViewModel.Api) {
  const [side, setSide] = React.useState("front");

  return (
    <>
      <ContentMolecule>
        <SegmentedButtons
          value={side}
          onValueChange={setSide}
          buttons={[
            {
              value: "front",
              label: "Frente",
            },
            {
              value: "back",
              label: "Verso",
              disabled: !props?.backTemplate,
            },
          ]}
        />
      </ContentMolecule>

      {props?.badge && props.frontTemplate && (
        <View style={{ display: "flex", alignItems: "center" }}>
          {side === "front" && (
            <CardFrontOrganism
              profileUri={props?.badge.front.profileUri}
              logoUri={props?.logoUri}
              backgroundUri={props?.frontTemplate?.backgroundUri}
              fields={BadgeDomainModel.getFields(
                props?.badge?.front,
                props.frontTemplate
              )}
            ></CardFrontOrganism>
          )}

          {props.badge.back && side === "back" && (
            <CardBackOrganism
              backgroundUri={props?.backTemplate?.backgroundUri}
              fields={BadgeDomainModel.getFields(
                props.badge.back,
                props?.backTemplate
              )}
            ></CardBackOrganism>
          )}
        </View>
      )}
    </>
  );
}
