import { StyleProp, ViewStyle } from "react-native/types";

const expirationSection: StyleProp<ViewStyle> = {
  display: "flex",
  flexDirection: "row",
  gap: 20,
};

const expirationitem: StyleProp<ViewStyle> = {
  flexGrow: 1,
  flexShrink: 1,
};

export default { expirationSection, expirationitem };
