import { StyleProp, ViewStyle } from "react-native/types";

const content: StyleProp<ViewStyle> = {
  display: "flex",
  alignItems: "center",
  gap: 20,
};

const cameraActions: StyleProp<ViewStyle> = {
  display: "flex",
  flexDirection: "row",
};

const ratio34 = {
  width: 300,
  height: 400,
};

export default { content, cameraActions, ratio34 };
