import { StyleProp, ViewStyle } from "react-native/types";

const container: StyleProp<ViewStyle> = { height: "100%" };

const fab: StyleProp<ViewStyle> = {
  position: "absolute",
  margin: 16,
  right: 0,
  bottom: 0,
};

const noBadgesHint: StyleProp<ViewStyle> = {
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: 16,
};

const addCardHint: StyleProp<ViewStyle> = {
  display: "flex",
  alignItems: "center",
  gap: 16,
  justifyContent: "center",
};

export default { container, fab, addCardHint, noBadgesHint };
