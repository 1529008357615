import { StyleProp, ViewStyle } from "react-native/types";

const actions: StyleProp<ViewStyle> = {
  display: "flex",
  flexDirection: "row",
  width: "100%",
  justifyContent: "space-between",
};

export default { actions };
