import { createSlice } from "@reduxjs/toolkit";
import { AuthStoreModel } from "./auth.store.model";
import { AuthStoreActions } from "./auth.store.actions";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { variables } from "../../variables/";

export const storeKey = "auth";
export const store = createSlice({
  name: storeKey,
  initialState: AuthStoreModel.initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      AuthStoreActions.requestToken.fulfilled,
      (state, action) => {
        AsyncStorage.setItem(
          variables.cognito.tokenLocalStorageKey,
          JSON.stringify(action.payload)
        );
      }
    );

    builder.addCase(AuthStoreActions.clearToken.fulfilled, (state, action) => {
      AsyncStorage.removeItem(variables.cognito.tokenLocalStorageKey);
    });
  },
});

export default store.reducer;
