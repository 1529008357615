import { StyleProp, ViewStyle } from "react-native/types";

const item: StyleProp<ViewStyle> = {
  width: "100%",
  paddingLeft: 12,
  paddingRight: 12,
  flexDirection: "row",
};

const itemActions: StyleProp<ViewStyle> = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  alignItems: "center",
};

const actions: StyleProp<ViewStyle> = {
  display: "flex",
  flexDirection: "row",
};
const container: StyleProp<ViewStyle> = { width: "100%" };

const styles = {
  item,
  itemActions,
  container,
  actions,
};

export default styles;
