import { StyleProp, ViewStyle } from "react-native/types";

const addressTitle: StyleProp<ViewStyle> = {
  display: "flex",
  flexDirection: "column",
};

const w100: StyleProp<ViewStyle> = { width: "100%" };

const styles = {
  w100,
  addressTitle,
};

export default styles;
