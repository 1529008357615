import { createAsyncThunk } from "@reduxjs/toolkit";
import { firstValueFrom } from "rxjs";
import { UserHttpService } from "../user.http.service";
import { AxiosError } from "axios";
import { UserDomainModel } from "../../../domain/user/user.domain.model";

export namespace UserStoreActions {
  export const getUser = createAsyncThunk(
    `getUser`,
    async (email: string, { rejectWithValue }) => {
      try {
        const user = await firstValueFrom(UserHttpService.getUser(email));
        return user;
      } catch (err) {
        return rejectWithValue((err as AxiosError).response?.status);
      }
    }
  );

  export const getUserAdresses = createAsyncThunk(
    `getUserAdresses`,
    async () => {
      const addressList = await firstValueFrom(
        UserHttpService.getUserAdresses()
      );
      return addressList;
    }
  );

  export const createUserAdress = createAsyncThunk(
    `createUserAdress`,
    async (address: UserDomainModel.Address) => {
      await firstValueFrom(UserHttpService.createUserAdress(address));
    }
  );

  export const createUser = createAsyncThunk(
    `createUser`,
    async (user: UserDomainModel.BasicUser) => {
      await firstValueFrom(UserHttpService.createUser(user));
    }
  );
}
