import { createSlice } from "@reduxjs/toolkit";
import { OrderStoreModel } from "./order.store.model";
import { OrderStoreActions } from "./order.store.actions";

export const storeKey = "order";
export const store = createSlice({
  name: storeKey,
  initialState: OrderStoreModel.initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(OrderStoreActions.getOrders.fulfilled, (state, action) => {
      state.orders = action.payload;
    });

    builder.addCase(OrderStoreActions.getOrder.fulfilled, (state, action) => {
      state.order = action.payload;
    });

    builder.addCase(
      OrderStoreActions.createOrder.fulfilled,
      (state, action) => {
        state.createdOrder = action.payload;
      }
    );

    builder.addCase(
      OrderStoreActions.uploadOrderImage.fulfilled,
      (state, action) => {}
    );

    builder.addCase(
      OrderStoreActions.confirmOrderValidation.fulfilled,
      (state, action) => {
        state.createdOrder = action.payload;
      }
    );
  },
});

export default store.reducer;
