import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { DeviceScreenContentComponent } from "../../dev-kit/components/device-screen-content/device-screen-content.component";
import { Devices, useDevice } from "../../hooks/use-device";
import { AddressSelection } from "./address-selection/address-selection.component";
import { CheckoutStackParamList } from "./checkout.navigation.model";
import { PaymentMethodSelection } from "./payment-method-selection/payment-method-selection.component";
import { ReviewBadges } from "./review-badges/review-badges.component";
import { Submit } from "./submit/submit.component";
import { Summary } from "./summary/summary.component";

const CheckoutStack = createNativeStackNavigator<CheckoutStackParamList>();

export function Checkout() {
  const device = useDevice();

  let headerBackground;

  if (device === Devices.DESKTOP) {
    headerBackground = () => <></>;
  }

  return (
    <DeviceScreenContentComponent>
      <CheckoutStack.Navigator
        screenOptions={{
          headerLeft: () => <></>,
        }}
      >
        <CheckoutStack.Screen
          name="PaymentMethodSelection"
          component={PaymentMethodSelection}
          options={{
            title: "Forma de pagamento",
            headerBackground,
          }}
        />

        <CheckoutStack.Screen
          name="AddressSelection"
          component={AddressSelection}
          options={{ title: "Selecione o endereço", headerBackground }}
        />

        <CheckoutStack.Screen
          name="Summary"
          component={Summary}
          options={{ title: "Resumo do pedido", headerBackground }}
        />

        <CheckoutStack.Screen
          name="ReviewBadges"
          component={ReviewBadges}
          options={{ title: "Revisar crachás", headerBackground }}
        />

        <CheckoutStack.Screen
          name="Submit"
          component={Submit}
          options={{ title: "Finalizando pedido", headerBackground }}
        />
      </CheckoutStack.Navigator>
    </DeviceScreenContentComponent>
  );
}
