import { useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import { View } from "react-native";
import { TextInputMask, TextInputMaskProps } from "react-native-masked-text";
import { Button, TextInput } from "react-native-paper";
import { useDispatch } from "react-redux";
import { OptionsSelectorMoleculeComponent } from "../../../../../components/molecules/options-selector/options-selector.component";
import { StylesContext } from "../../../../../context/styles/styles.context";
import { snackBarSubject } from "../../../../../dev-kit/snackbar.service";
import { NewOrderStoreActions } from "../../../../../domain/new-order/store/new-order.store.actions";
import { OrderDomainModel } from "../../../../../domain/order/order.domain.model";
import { AppDispatch } from "../../../../../domain/store";
import { variables } from "../../../../../variables";
import styles from "./credit-card-information-form.styles";
import { PagSeguro } from "./libs/pagseguro-lib";

export const CreditCardInformationFormComponent = (props: {
  onCreditCardSubmit: () => void;
}) => {
  const contextStyles = useContext(StylesContext);

  const dispatch = useDispatch<AppDispatch>();

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<OrderDomainModel.CreditCardFormData>({
    defaultValues: {
      expMonth: "",
      expYear: "",
      holderName: "",
      number: "",
      securityCode: "",
      holderDocument: "",
    },
    mode: "onChange",
  });

  const onSubmit = (data: OrderDomainModel.CreditCardFormData) => {
    const PagSeguroLib = PagSeguro({});
    console.log(data);

    const card = PagSeguroLib.encryptCard({
      publicKey: variables.pagSeguroPublicKey,
      holder: data.holderName,
      number: data.number,
      expMonth: data.expMonth,
      expYear: data.expYear,
      securityCode: data.securityCode,
    });

    const encryptedCard = card.encryptedCard;
    const hasErrors = card.hasErrors;
    // console.log(card.errors);

    if (hasErrors) {
      snackBarSubject.next("Você inseriu um cartão inválido");
      return;
    }

    const paymentData: OrderDomainModel.CreditCardPayment = {
      encryptedCard,
      gatewayId: "",
      holderName: data.holderName,
      holderDocument: data.holderDocument,
      paymentDateTime: "",
    };

    dispatch(NewOrderStoreActions.setPaymentData(paymentData)).then(
      props.onCreditCardSubmit
    );
  };

  return (
    <>
      <Controller
        control={control}
        rules={{
          required: true,
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextInput
            label="Nome do dono do cartão"
            mode="flat"
            placeholder="Nome do dono do cartão"
            onBlur={onBlur}
            onChangeText={onChange}
            value={value}
            error={!!errors.holderName}
          />
        )}
        name="holderName"
      />

      <Controller
        control={control}
        rules={{
          required: true,
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextInput
            label="CPF/CNPJ do dono do cartão"
            mode="flat"
            placeholder="CPF/CNPJ do dono do cartão"
            onBlur={onBlur}
            onChangeText={onChange}
            value={value}
            error={!!errors.holderDocument}
            render={(props) => {
              const maskProps = {
                ...props,
                type: "only-numbers",
              } as TextInputMaskProps;
              return <TextInputMask {...maskProps} />;
            }}
          />
        )}
        name="holderDocument"
      />

      <Controller
        control={control}
        rules={{
          required: true,
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextInput
            mode="flat"
            label="Número do cartão"
            placeholder="Número do cartão"
            onBlur={onBlur}
            onChangeText={onChange}
            value={value}
            error={!!errors.number}
            render={(props) => {
              const maskProps = {
                ...props,
                type: "only-numbers",
              } as TextInputMaskProps;
              return <TextInputMask {...maskProps} />;
            }}
          />
        )}
        name="number"
      />

      <View style={styles.expirationSection}>
        <View style={styles.expirationitem}>
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <OptionsSelectorMoleculeComponent
                dialogStyles={contextStyles.dialogStyle}
                label="Mês validade"
                value={value}
                options={Array.from(Array(12), (el, index) => ({
                  label: (index + 1).toString().padStart(2, "0"),
                  value: (index + 1).toString().padStart(2, "0"),
                }))}
                onOptionChange={onChange}
              />
            )}
            name="expMonth"
          />
        </View>

        <View style={styles.expirationitem}>
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <OptionsSelectorMoleculeComponent
                dialogStyles={contextStyles.dialogStyle}
                label="Ano validade"
                value={value}
                options={Array.from(Array(41), (el, index) => ({
                  label: (index + 2020).toString(),
                  value: (index + 2020).toString(),
                }))}
                onOptionChange={onChange}
              />
            )}
            name="expYear"
          />
        </View>
      </View>

      <Controller
        control={control}
        rules={{
          required: true,
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextInput
            mode="flat"
            label="CVV"
            placeholder="CVV"
            onBlur={onBlur}
            onChangeText={onChange}
            value={value}
            maxLength={3}
            error={!!errors.securityCode}
            render={(props) => {
              const maskProps = {
                ...props,
                type: "only-numbers",
              } as TextInputMaskProps;
              return <TextInputMask {...maskProps} />;
            }}
          />
        )}
        name="securityCode"
      />

      <Button
        disabled={!isValid}
        icon={"check"}
        mode="outlined"
        onPress={handleSubmit(onSubmit)}
      >
        Pronto
      </Button>
    </>
  );
};
