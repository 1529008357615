import React from "react";
import { View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { OrderStoreActions } from "../../domain/order/store/order.store.actions";
import { AppDispatch } from "../../domain/store";
import styles from "./dashboard.styles";
import OrderListOrganism from "../../components/organisms/order-list/order-list.component";
import { OrderStoreSelectors } from "../../domain/order/store/order.store.selectors";
import { FAB } from "react-native-paper";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { RootStackParamList } from "../../navigation/navigation.model";
import { AppStoreActions } from "../../domain/app/app.store.actions";
import { useIsFocused } from "@react-navigation/native";
import { NewOrderStoreSelectors } from "../../domain/new-order/store/new-order.store.selectors";

type Props = NativeStackScreenProps<RootStackParamList, "Dashboard">;

export function Dashboard({ navigation }: Props) {
  const isFocused = useIsFocused();
  const orders = useSelector(OrderStoreSelectors.selectOrders);
  const newOrderBadges = useSelector(NewOrderStoreSelectors.selectBadgeList);
  const dispatch = useDispatch<AppDispatch>();

  React.useEffect(() => {
    if (!isFocused) {
      return;
    }

    dispatch(AppStoreActions.setScreenBlockedForLoading(true));
    dispatch(AppStoreActions.setLoadingLabel("Carregando pedidos..."));

    dispatch(OrderStoreActions.getOrders()).then(() => {
      dispatch(AppStoreActions.setScreenBlockedForLoading(false));
    });
  }, [isFocused]);

  const newOrder = () => {
    navigation.navigate("NewOrder", {
      screen: newOrderBadges.length ? "BadgeList" : "CardType",
    });
  };

  return (
    <View style={styles.container}>
      <OrderListOrganism
        orderList={orders}
        selectedOrder={(selectedOrder) => {
          navigation.navigate("OrderInfo", { orderId: selectedOrder.id });
        }}
      />

      <FAB
        label={
          newOrderBadges.length
            ? "Continuar fazendo seu pedido"
            : "Criar crachás!"
        }
        icon={newOrderBadges.length ? "pencil" : "plus"}
        style={styles.fab}
        onPress={newOrder}
      />
    </View>
  );
}
