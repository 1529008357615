import { ScrollView } from "react-native";
import { ContentShellApiModel } from "../api.model";
import styles from "./styles";

export const ContentShellComponent = (props: ContentShellApiModel) => {
  return (
    <ScrollView style={styles.container}>
      {props.cameraActions}

      {props.badgePreview}

      {props.form}
    </ScrollView>
  );
};
