import { useIsFocused } from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import React from "react";
import { View } from "react-native";
import { ActivityIndicator } from "react-native-paper";
import { useDispatch } from "react-redux";
import { TokenService } from "../../dev-kit/token.service";
import { RootStackParamList } from "../../navigation/navigation.model";
import { AuthStoreActions } from "../../domain/auth/auth.store.actions";
import { AppDispatch } from "../../domain/store";
import { UserStoreActions } from "../../domain/user/store/user.store.actions";
import styles from "./authorization-code-receiver.styles";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { AuthDomainModel } from "../../domain/auth/auth.domain.model";

type Props = NativeStackScreenProps<
  RootStackParamList,
  "AuthorizationCodeReceiver"
>;

export function AuthorizationCodeReceiver({ navigation, route }: Props) {
  const isFocused = useIsFocused();
  const dispatch = useDispatch<AppDispatch>();

  React.useEffect(() => {
    if (!isFocused) {
      return;
    }

    const authorizationCode = route?.params?.code;

    if (!authorizationCode) {
      fail();
      return;
    }

    const authenticate = dispatch(
      AuthStoreActions.requestToken(authorizationCode)
    ).unwrap();
    authenticate.then(login, fail);
  }, [isFocused]);

  const login = async () => {
    const email = await TokenService.getPayloadValue<string>("email");
    if (!email) {
      fail();
      return;
    }

    const userInfo = dispatch(UserStoreActions.getUser(email)).unwrap();
    userInfo.then(
      async () => {
        const route = await AsyncStorage.getItem(
          AuthDomainModel.RouteAfterLoginStorageKey
        );
        navigation.reset({
          index: 0,
          routes: [
            { name: "Home" },
            { name: (route as keyof RootStackParamList) || "Dashboard" },
          ],
        });
      },
      (statusCode: number) => {
        if (statusCode === 404) {
          navigation.reset({
            index: 0,
            routes: [{ name: "Home" }, { name: "NewUser" }],
          });
          return;
        }
        fail();
      }
    );
  };

  const fail = () => {
    navigation.reset({
      index: 0,
      routes: [{ name: "Home" }],
    });
  };

  return (
    <View style={styles.container}>
      <ActivityIndicator size={"large"} animating={true} />
    </View>
  );
}
