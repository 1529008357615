import { createAsyncThunk } from "@reduxjs/toolkit";
import { firstValueFrom } from "rxjs";
import { OrderHttpService } from "../order.http.service";
import { OrderDomainModel } from "../order.domain.model";

export namespace OrderStoreActions {
  export const getOrders = createAsyncThunk(`getOrders`, async () => {
    const order = await firstValueFrom(OrderHttpService.getOrders());
    return order;
  });

  export const getOrder = createAsyncThunk(
    `getOrder`,
    async (orderId: string) => {
      const order = await firstValueFrom(OrderHttpService.getOrder(orderId));
      return order;
    }
  );

  export const createOrder = createAsyncThunk(
    `createOrder`,
    async (order: OrderDomainModel.Order) => {
      const newOrder = await firstValueFrom(
        OrderHttpService.createOrder(order)
      );
      return newOrder;
    }
  );

  export const uploadOrderImage = createAsyncThunk(
    `uploadOrderImage`,
    async (payload: {
      base64image: string;
      orderId: string;
      fileName: string;
    }) => {
      const uploadedImage = await firstValueFrom(
        OrderHttpService.uploadOrderImage(payload)
      );
      return uploadedImage;
    }
  );

  export const confirmOrderValidation = createAsyncThunk(
    `confirmOrderValidation`,
    async (orderId: string) => {
      const confirmedOrder = await firstValueFrom(
        OrderHttpService.confirmOrderValidation(orderId)
      );
      return confirmedOrder;
    }
  );
}
