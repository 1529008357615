import { Text } from "react-native-paper";
import { PropsWithChildren } from "react";

export default function DescriptionMolecule(
  props: PropsWithChildren<{
    bold?: boolean;
    color?: string;
    variant?: "bodyLarge" | "bodySmall";
    textAlign?: "auto" | "left" | "right" | "center" | "justify" | undefined;
    fontSize?: number;
  }>
) {
  return (
    <Text
      variant={props?.variant || "bodyLarge"}
      style={{
        fontWeight: props.bold ? "bold" : "normal",
        color: props.color,
        fontFamily: "IRoboto",
        textAlign: props.textAlign,
        fontSize: props.fontSize,
      }}
    >
      {props.children}
    </Text>
  );
}
