import { NativeStackScreenProps } from "@react-navigation/native-stack";
import React from "react";
import DescriptionMolecule from "../../../components/molecules/description/description.component";
import { CheckoutStackParamList } from "../checkout.navigation.model";
import ContentMolecule from "../../../components/molecules/content/content.component";
import OrderCostsDetailsOrganism from "../../../components/organisms/order-costs-details/order-costs-details.component";
import { useDispatch, useSelector } from "react-redux";
import { NewOrderStoreSelectors } from "../../../domain/new-order/store/new-order.store.selectors";
import { AppDispatch } from "../../../domain/store";
import { NewOrderStoreActions } from "../../../domain/new-order/store/new-order.store.actions";
import { Divider, FAB } from "react-native-paper";
import AddressItemMolecule from "../../../components/molecules/address-item/address-item.component";
import styles from "./summary.styles";
import { NavigationProp } from "@react-navigation/native";
import { RootStackParamList } from "../../../navigation/navigation.model";
import { AppStoreActions } from "../../../domain/app/app.store.actions";
import { OrderCostsDetailsModel } from "../../../components/organisms/order-costs-details/order-costs-details.model";
import { OrderDomainServicerderDomainService } from "../../../domain/order/order.domain.service";

type Props = NativeStackScreenProps<CheckoutStackParamList, "Summary">;

export function Summary({ navigation, route }: Props) {
  const dispatch = useDispatch<AppDispatch>();

  const [badgesUnitCost, setBadgesUnitCost] = React.useState(0);
  const [badgesTotalCost, setBadgesTotalCost] = React.useState(0);
  const [additionalProductsTotalCost, setAdditionalProductsTotalCost] =
    React.useState(0);

  const [parsedAdditionalProducts, setParsedAdditionalProducts] =
    React.useState<Array<OrderCostsDetailsModel.ProductDetails>>([]);

  const selected = useSelector(NewOrderStoreSelectors.selectSelected);
  const badgeList = useSelector(NewOrderStoreSelectors.selectBadgeList);
  const freightCost = useSelector(NewOrderStoreSelectors.selectFreightCost);
  const deliveryTime = useSelector(NewOrderStoreSelectors.selectDeliveryTime);
  const deliveryAddress = useSelector(
    NewOrderStoreSelectors.selectDeliveryAddress
  );
  const inOrderAdditionalProducts = useSelector(
    NewOrderStoreSelectors.selectInOrderAdditionalProducts
  );

  React.useEffect(() => {
    const cardTypeCost = selected?.cardType?.cost || 0;
    const frontTemplateCost = selected?.frontTemplate?.cost || 0;
    const backTemplateCost = selected?.backTemplate?.cost || 0;
    const unitCost = cardTypeCost + frontTemplateCost + backTemplateCost;
    setBadgesUnitCost(unitCost);
    setBadgesTotalCost(unitCost * badgeList.length);
  }, [selected, badgeList]);

  React.useEffect(() => {
    if (!deliveryAddress) {
      return;
    }
    dispatch(AppStoreActions.setScreenBlockedForLoading(true));
    dispatch(AppStoreActions.setLoadingLabel("Calculando frete..."));

    dispatch(
      NewOrderStoreActions.getFreightInfo(deliveryAddress?.zipCode || "")
    ).then(() => {
      dispatch(AppStoreActions.setScreenBlockedForLoading(false));
    });
  }, [deliveryAddress]);

  React.useEffect(() => {
    if (!inOrderAdditionalProducts || !badgeList) {
      return;
    }

    const additionalProducts = inOrderAdditionalProducts.map(
      (inOrderAdditionalProduct) => {
        let ammount = inOrderAdditionalProduct.quantity;

        if (inOrderAdditionalProduct.quantitySameAsBadgesLength) {
          ammount = badgeList.length;
        }

        const subTotalCost = ammount * inOrderAdditionalProduct.product.cost;

        return {
          unitCost: inOrderAdditionalProduct.product.cost,
          ammount,
          description: inOrderAdditionalProduct.product.name,
          subTotalCost,
        };
      }
    );

    setParsedAdditionalProducts(additionalProducts);
    setAdditionalProductsTotalCost(
      OrderDomainServicerderDomainService.calculateAdditionalProductsTotalCost(
        inOrderAdditionalProducts,
        badgeList.length
      )
    );
  }, [inOrderAdditionalProducts, badgeList]);

  return (
    <>
      <ContentMolecule>
        <OrderCostsDetailsOrganism
          productList={[
            {
              subTotalCost: badgesTotalCost,
              ammount: badgeList.length,
              description: `${selected?.cardType?.description}, Frente ${
                selected?.backTemplate ? "e verso" : "somente"
              }`,
              unitCost: badgesUnitCost,
            },
            ...parsedAdditionalProducts,
          ]}
          freightCost={freightCost}
          destinationZipCode={deliveryAddress?.zipCode || ""}
          totalCost={
            badgesTotalCost + additionalProductsTotalCost + freightCost
          }
        ></OrderCostsDetailsOrganism>
      </ContentMolecule>

      <Divider />
      <ContentMolecule>
        <DescriptionMolecule>
          Prazo de entrega: em até {deliveryTime}{" "}
          {deliveryTime > 1 ? "dias úteis" : "dia útil"}.
        </DescriptionMolecule>
      </ContentMolecule>
      <Divider />

      <ContentMolecule>
        <DescriptionMolecule>Endereço de entrega:</DescriptionMolecule>
      </ContentMolecule>

      {deliveryAddress && (
        <AddressItemMolecule
          selectedAddress={() => {
            navigation.navigate("AddressSelection");
          }}
          address={deliveryAddress}
        ></AddressItemMolecule>
      )}

      <FAB
        label="Crachás"
        icon="pencil"
        mode="flat"
        style={styles.fabReview}
        onPress={() => {
          navigation.getParent<NavigationProp<RootStackParamList>>()?.reset({
            index: 0,
            routes: [
              { name: "Home" },
              { name: "NewOrder", params: { screen: "CardType" } },
              { name: "NewOrder", params: { screen: "BadgeList" } },
            ],
          });
        }}
      />
      <FAB
        label="Finalizar pedido"
        icon="check"
        disabled={!freightCost}
        style={styles.fabProceed}
        onPress={() => {
          navigation.navigate("ReviewBadges");
        }}
      />
    </>
  );
}
