import * as React from 'react';
import { View } from 'react-native';
import DescriptionMolecule from '../../molecules/description/description.component';
import { OrderCostsDetailsModel } from './order-costs-details.model';
import styles from './order-costs-details.styles';
import CurrencyMolecule from '../../molecules/currency/currency.component';
import { Divider, Text } from 'react-native-paper';

export default function OrderCostsDetailsOrganism(props: OrderCostsDetailsModel.Api) {
    const renderList = () => {
        return props.productList.map((product, index) => {
            return (
                <View key={index} style={styles.row}>
                    <View style={styles.colProduct}>
                        <DescriptionMolecule>{product.ammount}x {product.description} - Un: <CurrencyMolecule>{product.unitCost}</CurrencyMolecule></DescriptionMolecule>
                    </View>
                    
                    <View style={styles.colCost}>
                        <DescriptionMolecule><CurrencyMolecule>{product.subTotalCost}</CurrencyMolecule></DescriptionMolecule>
                    </View>
                </View>
            )
        })
    }
    return (
        <View style={styles.container}>
            {renderList()}

            <Divider />

            <View key={'freight'} style={styles.row}>
                <View style={styles.colProduct}>
                    <DescriptionMolecule>Frete para {props.destinationZipCode}</DescriptionMolecule>
                </View>

                <View style={styles.colCost}>
                    <DescriptionMolecule><CurrencyMolecule>{props.freightCost}</CurrencyMolecule></DescriptionMolecule>
                </View>
            </View>

            <View key={'total'} style={styles.row}>
                <View style={styles.colProduct}>
                    <DescriptionMolecule>Total</DescriptionMolecule>
                </View>

                <View style={styles.colCost}>
                    <DescriptionMolecule>
                        <Text style={{ fontWeight: 'bold' }}>
                            <CurrencyMolecule>
                                {props.totalCost}
                            </CurrencyMolecule>
                        </Text>
                    </DescriptionMolecule>
                </View>
            </View>
        </View>
    );
};
