import { createNativeStackNavigator } from "@react-navigation/native-stack";
import React from "react";
import { RootStackParamList } from "./navigation.model";
import { Home } from "../pages/home/home.page";
import * as Linking from "expo-linking";
import { NavigationContainer } from "@react-navigation/native";
import { Dashboard } from "../pages/dashboard/dashboard.page";
import { AuthorizationCodeReceiver } from "../pages/authorization-code-receiver/authorization-code-receiver.page";
import { NewUser } from "../pages/new-user/new-user.page";
import { OrderInfo } from "../pages/order-info/order-info.page";
import { NewOrder } from "../pages/new-order/new-order.page";
import { Checkout } from "../pages/checkout/checkout.page";
import { setTopLevelNavigator } from "./navigation.service";
import { NavigationMenuComponent } from "./navigation-menu/navigation-menu.component";

const Stack = createNativeStackNavigator<RootStackParamList>();

export function Navigation() {
  const prefix = Linking.createURL("/");
  const linking = {
    prefixes: [prefix],
  };

  return (
    <NavigationContainer
      ref={(ref) => {
        if (!ref) return;
        setTopLevelNavigator(ref);
      }}
      linking={linking}
    >
      <Stack.Navigator
        screenOptions={{
          headerLeft: () => {
            return <NavigationMenuComponent></NavigationMenuComponent>;
          },
        }}
      >
        <Stack.Screen
          name="Home"
          component={Home}
          options={{ title: "Prime Express" }}
        />

        <Stack.Screen
          name="Dashboard"
          component={Dashboard}
          options={{ title: "Meus pedidos" }}
        />

        <Stack.Screen
          name="AuthorizationCodeReceiver"
          component={AuthorizationCodeReceiver}
          options={{ title: "Login", headerLeft: () => <></> }}
        />

        <Stack.Screen
          name="NewUser"
          component={NewUser}
          options={{ title: "Novo usuário", headerLeft: () => <></> }}
        />

        <Stack.Screen
          name="OrderInfo"
          component={OrderInfo}
          options={{ title: "Informações do Pedido" }}
        />

        <Stack.Screen
          name="NewOrder"
          component={NewOrder}
          options={{ title: "Criar crachás" }}
        />

        <Stack.Screen
          name="Checkout"
          component={Checkout}
          options={{ title: "Checkout" }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}
