import { BadgeDomainModel } from "../badge/badge.domain.model";
import { NewOrderDomainModel } from "../new-order/new-order.domain.model";
import { UserDomainModel } from "../user/user.domain.model";

export namespace OrderDomainModel {
  export interface Order {
    email: string;
    id: string;
    thumb: string;
    date: string;
    status: string;
    product: Product;
    deliveryAddress: UserDomainModel.Address;
    freightCost: number;
    totalCost: number;
    valid: boolean;
    additionalProducts: Array<InOrderAdditionalProduct>;
    paymentMethod: PaymentMethod;
    payment: PaymentData;
  }

  export type PaymentData = PixPayment | CreditCardPayment;

  export interface Payment {
    gatewayId: string;
    paymentDateTime?: string;
  }

  export interface PixPayment extends Payment {
    qrcode: string;
    qrCodeId: string;
    textCode: string;
  }

  export interface CreditCardPayment extends Payment {
    encryptedCard: string;
    holderName: string;
    holderDocument: string;
  }

  export enum PaymentMethod {
    PIX = "PIX",
    CREDIT_CARD = "CREDIT_CARD",
  }

  export interface CreditCardFormData {
    holderName: string;
    holderDocument: string;
    number: string;
    expMonth: string;
    expYear: string;
    securityCode: string;
  }

  export interface Product {
    cardType: BadgeDomainModel.CardType;
    frontTemplate: BadgeDomainModel.Template;
    backTemplate?: BadgeDomainModel.Template;
    badgeList: Array<BadgeDomainModel.Badge>;
    logoUri?: string;
  }

  export interface InOrderAdditionalProduct {
    product: NewOrderDomainModel.AdditionalProduct;
    quantity: number;
    quantitySameAsBadgesLength: boolean;
    selectedOptions: Array<SelectedOption>;
  }

  interface SelectedOption {
    key: string;
    value: string;
  }
}
