export namespace AppStoreModel {
  export interface StoreModel {
    isScreenBlockedForLoading: boolean;
    loadingLabel: string;
  }

  export const initialState: StoreModel = {
    loadingLabel: "",
    isScreenBlockedForLoading: false,
  };
}
