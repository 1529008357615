import { View } from "react-native";
import { ContentShellApiModel } from "../api.model";
import styles from "./styles";

export const ContentShellComponent = (props: ContentShellApiModel) => {
  return (
    <View style={styles.container}>
      {props.badgePreview}

      <View style={styles.actions}>
        {props.selectionButton}

        {props.removeLogoButton}

        {props.confirmButton}
      </View>
    </View>
  );
};
