import { useEffect, useState } from "react";
import { Pressable } from "react-native";
import { Chip, TextInput } from "react-native-paper";
import { OptionsSelectorOrganismModel } from "./options-selector.model";
import { SelectorModalComponent } from "./selector-modal/selector-modal.component";

export function OptionsSelectorMoleculeComponent(
  props: OptionsSelectorOrganismModel.Api
) {
  const [selectorVisibility, setSelectorVisibility] = useState(false);

  const [selectedOption, setSelectedOption] =
    useState<OptionsSelectorOrganismModel.Option>();

  useEffect(() => {
    setSelectedOption(
      props.options?.find((option) => props.value === option.value)
    );
  }, [props.value]);

  const onPress = () => {
    setSelectorVisibility(true);
  };

  return (
    <>
      {!props.chipMode && (
        <Pressable disabled={props.disabled} onPress={onPress}>
          <TextInput
            disabled={props.disabled}
            mode="flat"
            label={props.label}
            value={selectedOption?.label || ""}
            editable={false}
          />
        </Pressable>
      )}

      {props.chipMode && (
        <Chip onPress={onPress} icon={props.chipIcon} disabled={props.disabled}>
          {selectedOption?.label || props.label}
        </Chip>
      )}

      <SelectorModalComponent
        input={{
          dialogStyles: props.dialogStyles,
          visible: selectorVisibility,
          value: props.value,
          label: props.label,
          options: props.options,
        }}
        events={{
          onDismiss() {
            setSelectorVisibility(false);
          },
          onOptionSubmit(option) {
            setSelectorVisibility(false);
            props.onOptionChange(option);
          },
        }}
      />
    </>
  );
}
