import { StyleProp, ViewStyle } from "react-native/types";

const itemIcon: StyleProp<ViewStyle> = {};

const container: StyleProp<ViewStyle> = { width: "100%", height: "100%" };

const styles = {
  itemIcon,
  container,
};

export default styles;
